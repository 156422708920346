export default class AbstractMqttManager {
    static topics_to_listen: string[] = []
    static topics_to_publish: string[] = []
    onMessageCallback: any = null;
    mqttClient: any = null;

    constructor(configs: any) {
        AbstractMqttManager.topics_to_listen = configs.listen_to;
        AbstractMqttManager.topics_to_publish = configs.publish_to;
    }

    onMessage(topic: string, message: any) {
        if (this.onMessageCallback != null)
            this.onMessageCallback()
    }

    registerReceiverCallback(callback: any) {
        // use receiver callback when you need to do some changes in the UI
        this.onMessageCallback = callback
    }
}
