<template>
  <ul class="flex flex-col w-full">
    <li v-if="compactView===0" class="flex flex-row flex-grow" @click="expandActions=!expandActions">
      <p class="color_disabled -ml-2 text-sm uppercase">{{ $t("str_actions") }} </p>
      <button class=" inline-flex rounded-md text-gray-400 hover:text-gray-500">
        <ChevronDownIcon v-show="expandActions" class="flex flex-row h-6 w-5 stroke-gray-500 hover:stroke-gray-200"/>
        <ChevronRightIcon v-show="!expandActions" class="flex flex-row h-6 w-5 stroke-gray-500 hover:stroke-gray-200"/>
      </button>
    </li>
    <li v-show="expandActions">
      <div v-for="(action_item, index) in selectedActions" :key="action_item.action.actionType+(action_item.actionSequenceId ?? index)">
        <div class="flex flex-row flex-grow">
          <p class="color_enabled">
            {{ $t("str_action_type") }}
          </p>
          <div class="flex-grow"/>
          <button type="button" @click="removeAction(index)"
                  class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <TrashIcon class="h-6 w-6 p-0.5" aria-hidden="true"/>
          </button>
        </div>
        <div class="flex flex-row flex-grow">
          <Dropdown
              :elem-list="availableActions.map(a=>JSON.parse(JSON.stringify(a)).actionType)"
              :prompt="selectedActions[index]?.action?.actionType"
              :default-selection-id="selectedActions[index]?.action?.actionType"
              @selectedEl="(type)=>setSelectedActionType(type, index)"/>
        </div>
        <div v-if="selectedActions[index]?.action?.predefined_action !== null">
          <ul role="list">
            <li class="flex flex-row flex-grow">
              <p class="w-full mb-2" v-if="!selectedActions[index]?.action?.predefined_action">
                <input class="w-full rounded" v-model="action_item.action.actionType">
              </p>
            </li>
            <li v-if="compactView===0" class="flex flex-row flex-grow">
              <p class=" mb-2  color_enabled">
                {{ $t("str_action_description") }}:
              </p>
            </li>
            <li v-if="compactView===0" class="flex flex-row flex-grow  color_disabled">
              <p class=" mb-2" v-if="selectedActions[index]?.action?.predefined_action">
                {{ action_item.action.actionDescription }}
              </p>

            </li>
            <li v-if="compactView===0" class="flex flex-row flex-grow">
              <p class="w-full mb-2" v-if="!selectedActions[index]?.action?.predefined_action">
                <input class="w-full rounded" v-model="action_item.action.actionDescription">
              </p>
            </li>
            <li v-if="compactView===0" class="flex flex-row flex-grow">
              <p class=" mb-2  color_enabled">
                {{ $t("str_blocking_type") }}:
              </p>
            </li>
            <li v-if="compactView===0" class="flex flex-row flex-grow  color_disabled">
              <p class=" mb-2" v-if="selectedActions[index]?.action?.predefined_action">
                {{ action_item.action.blockingType }}
              </p>
            </li>
            <li class="flex flex-row flex-grow">
              <Dropdown :elem-list="blockingTypes"
                        :prompt="selectedActions[index]?.blockingType ?? selectedActions[index]?.action?.blockingType"
                        :default-selection-id="selectedActions[index]?.blockingType ?? selectedActions[index]?.action?.blockingType"
                        @selectedEl="(type)=>setBlockingType(type, index)"/>
            </li>
            <li class="flex flex-row flex-grow cursor-pointer"
                @click="hideActionParameters[index]=!hideActionParameters[index]">
              <p class=" color_enabled  mb-2 hover:text-gray-200">{{ $t("str_parameters") }}</p>
              <button class=" inline-flex rounded-md text-gray-400 hover:text-gray-200">
                <ChevronDownIcon v-show="!hideActionParameters[index]"
                                 class="flex flex-row h-6 w-5 stroke-gray-500 hover:stroke-gray-200"/>
                <ChevronRightIcon v-show="!!hideActionParameters[index]"
                                  class="flex flex-row h-6 w-5 stroke-gray-500 hover:stroke-gray-200"/>
              </button>
            </li>
          </ul>
          <div v-show="!hideActionParameters[index]" v-for="actionParam in action_item.actionParameters" :key="action_item.action.actionType + (action_item.sequence_id ?? index)">
            <ul role="list">
              <li class="flex flex-row flex-grow">
                <StyledInput class="mb-0 mt-3" v-model="actionParam.key" :placeholder="$t('str_key')" type="text" :floating="true"/>
              </li>
              <li class="flex flex-row flex-grow mb-4">
                <StyledInput class="mt-3 mb-0" v-model="actionParam.value" :placeholder="$t('str_value')" type="text" :floating="true"/>
              </li>
            </ul>
          </div>
          <div class="flex flex-row flex-grow mx-8 mb-4">
            <button @click="addNewActionParam(index)" class="border w-full rounded-md py-1 button2">
              + {{ $t("str_add_new_parameter") }}
            </button>
          </div>
        </div>
      </div>
    </li>

    <li class="flex flex-row flex-grow mb-2 mx-8 mt-3">
      <button @click="addNewAction" class="border w-full rounded-md py-1 button2">
        + {{ $t("str_add_action") }}
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import {ChevronDownIcon, ChevronRightIcon, TrashIcon} from '@heroicons/vue/20/solid'
import Dropdown from "@/components/Dropdown.vue";</script>

<script lang="ts">
import {defineComponent} from "vue";

import * as ApiManager from "../network/ApiManager";
import * as AlertManager from '@/datamanagers/AlertsManager'
import {actionArrayChanged, prod_safe_log, zip} from '../utils'
import {liveViewEditorState} from "@/dtos/AppState";
import * as StorageManager from "@/datamanagers/StorageManager";
import {AgvPosition, vehicleStates} from '@/dtos/VehicleState'
import StyledInput from './StyledInput.vue';

export default defineComponent({
  name: "ActionsView",
  data() {
    return {
      blockingTypes: [
        "NONE",
        "SOFT",
        "HARD"
      ],
      hideActionParameters: [false] as Boolean[],
      expandActions: true,
      selectedActions: [] as any[],
      availableActions: [] as any[],
    }
  },
  props: {
    scope: {
      type: Number,
      default: 0
    },
    savedActions: {
      type: [] as any[],
      default: [] as any[]
    },
    // for some reason true/false didn't work,
    // opting for 0 = false, positive values = true
    compactView: {
      type: Number,
      default: Number(0.)
    }
  },
  watch: {
    selectedActions: {
      handler(newValue, oldValue) {
        this.emitResultActions()
        if (this.availableActions.length == 0)
          this.getActions();
      },
      deep: true
    },
    savedActions(newValue, oldValue) {
      const dataChanged = actionArrayChanged(oldValue, newValue)
      if (!newValue?.length || dataChanged) {
        this.selectedActions = newValue
        if (this.availableActions.length == 0)
          this.getActions();
      }
    },
  },
  mounted() {
    this.selectedActions = this.savedActions;
  },
  methods: {
    emitResultActions() {
      const actions = this.formatActionsForPayload()
      this.$emit('resultActions', actions);
      // console.log('emitting actions', actions)
    },
    setSelectedActionType(actionType: any, index: number) {
      if (this.selectedActions[index].action.actionType !== actionType) {
        this.selectedActions[index].actionParameters = [];
      }
      this.selectedActions[index].action.actionType = actionType;
      const refAction = JSON.parse(JSON.stringify(this.availableActions.find(value => value.actionType === actionType)));
      this.selectedActions[index].action.actionId = refAction.actionId;
      this.selectedActions[index].action.blockingType = refAction.blockingType;
      this.selectedActions[index].action.actionDescription = refAction.actionDescription;
      this.selectedActions[index].actionParameters = refAction.actionParameters;
    },
    getActions() {
      let actions: any [] = []
      if (this.scope === 0)
        actions = StorageManager.getActions().filter((action: any) => action.scope_node == true)
      else if (this.scope === 1)
        actions = StorageManager.getActions().filter((action: any) => action.scope_edge == true)
      else if (this.scope === 2)
        actions = StorageManager.getActions().filter((action: any) => action.scope_instant == true)

      actions = actions.map((obj: any) => {
        return {...obj, predefined_action: true}
      });
      this.availableActions = actions;
    },
    removeAction(i: any) {
      const removed_action = this.selectedActions.splice(i, 1)
    },
    formatActionsForPayload() {
      const actions = this.selectedActions.map((obj: any) => {
        return {'actionId': obj?.action?.actionId, 'actionParameters': obj?.actionParameters, 'blockingType': obj?.action?.blockingType}
      });
      return actions
    },
    addNewAction() {
      this.selectedActions.push({
        action: {
          actionId: 0,
          predefined_action: true,
          actionDescription: "",
          actionType: "",
          blockingType: "",
        },
        actionParameters: []
      })
      this.hideActionParameters.push(false)
    },
    addNewActionParam(i: any) {
      this.selectedActions[i].actionParameters.push({key: "", value: ""})
      this.hideActionParameters[i] = false
    },
    setBlockingType(blockingType: string, index: number) {
      this.selectedActions[index].action.blockingType = blockingType
    },
  },
})
</script>

<style scoped>

</style>
