/**
 * Reactive states store real-time data and automatically re-renders data/updates subscribers
 */

import { reactive } from 'vue'

// state of the job filters
export const orderFilterState = reactive({
  processed_selected: false,
  canceled_selected: false
})

export const graphDebugState = reactive({
  nodes: {},
  edges: {},
  vehicles: {} as unknown as any
})

export const loadingIndicator = reactive({
  counter: 0
});

// state of the current user
export const currentUserState = reactive({
  joined_date: '',
  username: '',
  role: {
    permissions: [] as any[],
    name: '',
    id: 1,
    description: '',
    role_level: null as number | null
  },
  name: '',
  email: ''
});
