<!-- 
  UI component to show dialog popup. Responsible to handle two different options.
 -->
<template>
  <div :class="{
    'w-full bg-opacity-75': showDialog,
    'w-0 bg-opacity-0': !showDialog,
    'fixed inset-0 bg-gray-900 transition-colors': true
  }"></div>
  <div v-if="showDialog" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 z-10 pl-[70px] md:pl-0 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" id="dialogWrapper"
        v-on:click="outsideClickHandler">
        <div id="dialogPanel"
          class="relative transform overflow-hidden rounded-lg color_background_light text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="color_background_light color- px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" aria-hidden="true" class="h-12 w-12 color_accent font-thin"
                style="stroke-width: 0.8; min-width:3rem; min-height: 3rem">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path>
              </svg>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold leading-6 color_enabled" id="modal-title">{{ title }}</h3>
                <div class="mt-2">
                  <p style="white-space: pre-wrap;" class="text-sm color_enabled">{{ message }}</p>
                </div>
              </div>
            </div>
            <!-- <div class="mt-3 ml-5">
              <label for="confirmOrderDeletion" class="text-m color_enabled"><input type="checkbox"
                  id="confirmOrderDeletion" name="confirmOrderDeletion"> Yes, I confirm the deletion</label>
            </div> -->
          </div>
          <div class="color_background_light px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button type="button" v-on:click="_confirm" :class="(okButtonType ? okButtonType : 'deletebutton') + ' ml-0 md:ml-2 border rounded-lg w-full p-1'">{{
              okButton }}</button>
            <button type="button" v-on:click="_cancel" class="button2 border rounded-lg w-full p-1">{{ cancelButton }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: "DialogPopup",
  data() {
    return {
      showDialog: false,
      title: '',
      message: '', // Main text content
      okButton: 'Confirm', // Text for confirm button;
      okButtonType: 'deletebutton', // Text for confirm button;
      cancelButton: 'Go Back', // text for cancel button
      resolvePromise: (a: any) => { },
      rejectPromise: (a: any) => { },
    };
  },
  props: {
    title: {
      type: String,
    },
    message: {
      type: String
    },
    okButton: {
      type: String
    },
    okButtonType: {
      type: String,
    },
    cancelButton: {
      type: String
    }
  },
  methods: {
    outsideClickHandler(e: any) {
      if (e.target.id === "dialogWrapper") {
        this._cancel();
      }
    },
    closeDialog() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
    show(opts: any) {
      this.title = opts.title || '';
      this.message = opts.message || '';
      this.okButton = opts.okButton || 'Confirm';
      this.okButtonType = opts.okButtonType || 'deletebutton';
      this.cancelButton = opts.cancelButton || 'Cancel';
      this.showDialog = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },
    _confirm() {
      this.closeDialog();
      this.resolvePromise(true);
    },

    _cancel() {
      this.closeDialog();
      this.resolvePromise(false);
    },
  },
})

</script>
