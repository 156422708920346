/**
 * Reactive state for all Edges
 */

import { LiveViewHelper } from '@/helpers/LiveViewHelper';
import { TrajectoryData } from '@/helpers/TrajectoryHelper';
import { useConfig } from '@/main';
import { reactive } from 'vue';

// state of the current user
export const EdgesManager = reactive({
  edges: new Map<number, any>(), // all edges cached.
  trajectories: new Map<number, any>(), // all trajectories instances cached.
  lastUpdatedTimestamp: null as null | number, // timestamp of the last made update to request only updated elements from the API
  editedEdge: null as null | number, // ID of the edited edge. TODO - store clone of the edge for possibility to revert changes (?).
  add(newEdge: any) {
    // console.log('edgesState addEdge ', Number(newEdge.edgeId));
    if (!newEdge.edgeId)
      console.log(newEdge);
    const processedEdge = processEdgeBeforeCaching(newEdge);
    this.edges.set(Number(processedEdge.edgeId), processedEdge);
    this.lastUpdatedTimestamp = new Date().valueOf();
  },
  delete(edgeId: any) {
    // console.log('edgesState deleteEdge ', edgeId);
    this.edges.delete(edgeId);
    this.trajectories.delete(edgeId);
    this.lastUpdatedTimestamp = new Date().valueOf();
  },
  update(updatedEdge: any) {
    // console.log('edgesState updateEdge ', updatedEdge.edgeId);
    const processedEdge = processEdgeBeforeCaching(updatedEdge);
    this.edges.set(Number(processedEdge.edgeId), processedEdge);
    this.lastUpdatedTimestamp = new Date().valueOf();
  },
  clear() {
    this.edges.clear();
    this.trajectories.clear();
    // console.log('edgesState clear');
  },
  setEditedEdge(edgeToEdit: any) {
    // console.log('edgesState setEditedEdge');
    const clonedEdge = structuredClone(edgeToEdit);
    const processedEdge = processEdgeBeforeCaching(clonedEdge, NaN);
    this.edges.set(NaN, processedEdge);
  },
  updateEditedEdge(updatedEditedEdge: any) {
    // console.log('edgesState updateEditedEdge')
    const processedEdge = processEdgeBeforeCaching(updatedEditedEdge, NaN);
    this.edges.set(NaN, processedEdge);
  }
});

function processEdgeBeforeCaching(edge: any, id?: any) {
  if (id === undefined) id = edge.edgeId;
  const clonedEdge = structuredClone(edge);
  const trajectory = new TrajectoryData();
  const apiTrajectory = edge.trajectory;
  if (apiTrajectory != null && apiTrajectory.inputPoints?.length > 0) {
    trajectory.inputPoints = apiTrajectory.inputPoints;
  }
  const curve = LiveViewHelper.createCurveFromApi(apiTrajectory);
  if (curve != null) {
    trajectory.nurbsCurveVda = apiTrajectory;
    trajectory.addCurve(curve);
  }
  EdgesManager.trajectories.set(id, trajectory);
  return clonedEdge;
};