import {createApp, ref} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import BarcodeView from "@/customfeatures/knauf/BarcodeView.vue";
import configuration from '!raw-loader!./configuration.yml';
import {parse} from 'yaml'
import './index.css'
// import {initLocalStorage} from './datamanagers/StorageManager'
import i18n from "./i18n/index";


let leitstelle_config = parse(configuration);

// TODO - Take config from the Local Storage first and send async request to get config from the API. When API returns the answer - replace config with the one stored in DB.

const storedGlobalConfig = JSON.parse(localStorage.config ? localStorage.config : null);
const storedGlobalConfigVersion = localStorage.globalConfigVersion ? localStorage.globalConfigVersion : 0;
const globalConfigVersion = ref(storedGlobalConfigVersion);
const config = ref(storedGlobalConfig ? storedGlobalConfig : JSON.parse(JSON.stringify(leitstelle_config)));
const storedLocalConfig = JSON.parse(localStorage.localConfig ? localStorage.localConfig : null);
const localConfig = ref(storedLocalConfig ? storedLocalConfig : {});
localStorage.config = JSON.stringify(config.value);

function useConfig() {
  const setConfig = (newConfig: any) => {
    config.value = {...newConfig};
    localStorage.config = JSON.stringify(config.value);
  };

  const updateConfig = (newConfig: any) => {
    config.value = {...config, ...newConfig};
    localStorage.config = JSON.stringify(config.value);
  };

  const setConfigVersion = (newVersion: number) => {
    globalConfigVersion.value = newVersion;
    localStorage.globalConfigVersion = globalConfigVersion.value;
  };

  const updateLocalConfig = (newLocalConfig: any) => {
    localConfig.value = {...localConfig.value, ...newLocalConfig};
    localStorage.localConfig = JSON.stringify(localConfig.value);
  };

  const setLocalConfig = (newLocalConfig: any) => {
    localConfig.value = {...newLocalConfig};
    localStorage.localConfig = JSON.stringify(localConfig.value);
  };

  return { config: config?.value, localConfig: localConfig?.value, globalConfigVersion: globalConfigVersion?.value, setConfig, setConfigVersion, updateConfig, setLocalConfig, updateLocalConfig };
}

export {leitstelle_config, useConfig};

if (config) {
  createApp(App).use(router).use(i18n).mount('#app');
  if (useConfig().config.configuration?.custom_features?.mqtt?.knauf != null) {
    router.addRoute({
      path: '/knauf_scan',
      name: 'knaufscans',
      component: BarcodeView
    })
  }
}
