/**
 * The MqttManager is responsible for handling connection with MQTT broker on the server.
 * Subscribes to topics specified in configs and sends command to reset motors
 */

import mqtt from 'mqtt'
import {Connection, Factsheet, VehicleState, vehicleStates} from "@/dtos/VehicleState";
import {CustomMqttHelper} from "@/customfeatures/CustomMqttHelper";
import AbstractMqttManager from "@/customfeatures/AbstractMqttManager";
import {ActionTypes} from "@/dtos/Actions";
import * as StorageManager from "@/datamanagers/StorageManager";
import {connectionStates} from "@/datamanagers/StateIndicatorsManager";
import {graphDebugState} from "@/datamanagers/ReactiveStates";
import { useConfig } from '@/main';

class MqttClient {

    private client: any;
    private topicsToListen: string[];
    private connectUrl: string;
    private options: any;
    private customMqttManager: CustomMqttHelper;
    private config: any;

    private static instance: any;
    private listenToGraphDebug: boolean;

    private constructor(config: any, host: string, httpProtocol: string) {
        this.client = null
        this.topicsToListen = []
        this.config = config
        console.log("http protocol", httpProtocol)
        console.log("use ssl ", config?.useSsl)
        const protocol = config?.useSsl || httpProtocol === "https:" ? 'wss' : 'ws'
        console.log("websocket protocol", protocol)
        const connection = {
            port: this.config.port,
            endpoint: '/mqtt',
            clean: true,
            keepalive: 5,
            connectTimeout: 15000,
            reconnectPeriod: 1000,
            clientId: this.config.clientId + Math.random(),
        }

        this.connectUrl = `${protocol}://${host}:${connection.port}${connection.endpoint}`
        this.options = connection
        this.customMqttManager = new CustomMqttHelper()
        this.listenToGraphDebug = this.config?.listen_to_graph_debug ?? false
    }

    addCustomMqttManager(m: AbstractMqttManager) {
        this.customMqttManager.addCustomManager(m)
    }

    parseTopicsToListen() {
        const topic_names = [this.config.TOPIC_ORDER,
            this.config.TOPIC_STATE,
            this.config.TOPIC_VISUALIZATION,
            this.config.TOPIC_CONNECTION,
            this.config.TOPIC_FACTSHEET]

        this.topicsToListen = []
        topic_names.forEach(t => this.topicsToListen.push([this.config.interfaceName,
            this.config.majorVersion,
            this.config.manufacturer, "+", t].join("/")))
        if (this.listenToGraphDebug)
            this.topicsToListen.push([this.config.interfaceName,
                this.config.majorVersion,
                this.config.manufacturer, "debug_graph_state"].join("/"))
        // to see mqtt message about status, not used in code, but useful for debugging fleetmanagement
        this.topicsToListen.push([this.config.interfaceName,
            this.config.majorVersion,
            this.config.manufacturer, "debug_control_state"].join("/"))
        this.customMqttManager.getTopicsToListen().forEach((t: string) => this.topicsToListen.push(t))
    }

    addTopicToListen(topic: string) {
      // TODO - check if client is already connected before subscribing.
      this.client.subscribe(topic, (err: any, topic: any) => {
          if (err != null) {
              console.debug("subscribe error " + err)
          }
      })
    }

    connect() {
        try {
            this.client = mqtt.connect(this.connectUrl, this.options);
            this.client.on('connect', () => {
                console.debug('Connection to mqtt succeeded!');
                connectionStates.is_mqtt_active = true;
                this.topicsToListen.forEach((topic: any) => {
                    // prod_safe_log(topic)
                    this.client.subscribe(topic, (err: any, topic: any) => {
                        if (err != null) {
                            console.debug("subscribe error " + err)
                        }
                    })
                })
            });


            this.client.on('error', (error: any) => {
                console.debug('Connection failed', error);
                connectionStates.is_mqtt_active = false;
            });

            this.client.on('close', (error: any) => {
              console.debug('mqtt connection closed badly', error);
              connectionStates.is_mqtt_active = false;
            });

            this.client.on('offline', (error: any) => {
              console.debug('mqtt connection failed - offline', error);
              connectionStates.is_mqtt_active = false;
            });

            this.client.on('end', (error: any) => {
              console.debug('mqtt connection interrupted', error);
              connectionStates.is_mqtt_active = false;
            });

            this.client.on('disconnect', (error: any) => {
              console.debug('mqtt disconnected', error);
              connectionStates.is_mqtt_active = false;
            });

            this.client.on('message', (topic_full: any, message: any) => {
                if (useConfig().localConfig.log_mqtt_messages_to_console)
                  console.debug("received message from mqtt", topic_full);

                const messageProcessed = this.customMqttManager.onMessage(topic_full, message)
                if (messageProcessed) return

                const topic = topic_full.split('/')[4]
                const vehicle_id: string = topic_full.split('/')[3]
                // prod_safe_log("mqtt", vehicle_id, topic)
                const json_obj = JSON.parse((`${message}`))
                // prod_safe_log("mqtt json_obj", topic_name, json_obj, )
                if (vehicle_id === 'controlCenter') {
                  if (topic === 'energySavingMode') {
                    return connectionStates.is_power_saving_mode_activated = json_obj.enabled;
                  }
                }
                try {
                    localStorage.setItem(`${vehicle_id}_${topic}`, message)
                } catch (e) {
                    console.error("Caught exception when setting item in the storage")
                    console.error(e)
                    localStorage.clear()
                }
                let vehicle = vehicleStates.get(vehicle_id)
                switch (topic) {
                    case this.config.TOPIC_ORDER:

                        break
                    case this.config.TOPIC_STATE:
                        this.checkNameForId(vehicle_id)
                        if (vehicle != null) {
                            vehicle.updateState(json_obj)
                            // vehicle.connected = true
                            // vehicleStates.set(vehicle_id, vehicle)
                        } else {
                            const state = new VehicleState(json_obj)
                            // state.connected = true
                            vehicleStates.set(vehicle_id, state)
                        }
                        break
                    case this.config.TOPIC_VISUALIZATION:
                        if (graphDebugState.vehicles[vehicle_id] == undefined)
                            graphDebugState.vehicles[vehicle_id] = {}
                        graphDebugState.vehicles[vehicle_id]["box"] = json_obj?.vehicle_box
                        graphDebugState.vehicles[vehicle_id]["safety_box"] = json_obj?.safety_box
                        break
                    case this.config.TOPIC_CONNECTION:
                        this.checkNameForId(vehicle_id)
                        const connected = json_obj?.connectionState === "ONLINE"
                        if (vehicle == null) {
                            vehicle = new VehicleState(JSON.parse("{}"))
                        }
                        vehicle.connected = connected
                        vehicle.connection = new Connection(json_obj)
                        vehicle.show_on_map = !(this.config.set_vehicle_visibility_to_connected && !connected);
                        vehicleStates.set(vehicle_id, vehicle)
                        console.debug("Set new connection for vehicle ", vehicle_id, "connection state: ", json_obj?.connectionState)
                        break
                    case this.config.TOPIC_FACTSHEET:
                        const name = json_obj?.typeSpecification?.seriesName
                        if (!vehicle) {
                            vehicle = new VehicleState(JSON.parse("{}"))
                        }
                        vehicle.name = name
                        vehicle.factsheet = new Factsheet(json_obj)
                        vehicleStates.set(vehicle_id, vehicle)
                        break
                    default:
                        break;
                }
                if (vehicle_id == "debug_graph_state") {
                    if (this.listenToGraphDebug) {
                        graphDebugState.nodes = json_obj?.nodes
                        graphDebugState.edges = json_obj?.edges
                    }
                }
            })
        } catch (error) {
            console.error('mqtt.connect error', error);
            connectionStates.is_mqtt_active = false;
        }

    }

    publishMessage(topic: string, message: any, qos: number) {
        this.client.publish(topic, JSON.stringify(message), {qos: qos})
    }

    publishInstantAction(vehicleId: any, message: any) {
        const config = this.config
        const publish_topic = [config.interfaceName, config.majorVersion, config.manufacturer, vehicleId, config.TOPIC_INSTANT_ACTIONS].join("/")
        this.publishMessage(publish_topic, message, 0)
    }

    static getInstance(config: any, host: string, protocol: string) {
        if (this.instance == null && config != null && host != undefined) {
            this.instance = new MqttClient(config, host, protocol)
        }
        return this.instance
    }

    checkNameForId(vehicleId: string) {
        if (vehicleStates.has(vehicleId) && vehicleStates.get(vehicleId)?.name == null) {
            const msq = StorageManager.getAction(ActionTypes.FACTSHEET_REQUEST)
            if (msq.length > 0 && !(this.config?.simulate_vehicles ?? false))
                this.publishInstantAction(vehicleId, msq[0])
        }
    }
}

function publishInstantAction(configs: any, host: string, protocol: string, vehicleId: any, message: any) {
    const client = MqttClient.getInstance(configs, host, protocol)
    if (client != null) client.publishInstantAction(vehicleId, message)
    else console.error("Trying to publish instant action, but the mqtt client is null")
}

function publishControlCenterMessage(configs: any = null, host: string, protocol: string, topic: string, message: any) {
    publishMqqtMessage(configs, host, protocol, topic, message, 0)
}

function publishMqqtMessage(configs: any = null, host: string, protocol: string, topic: string, message: any, qos: number) {
    const client = MqttClient.getInstance(configs, host, protocol)
    if (client != null) client.publishMessage(topic, message, qos)
    else console.error("Trying to publish mqtt message, but the mqtt client is null")
}

export {MqttClient, publishInstantAction, publishMqqtMessage, publishControlCenterMessage};
