import AbstractMqttManager from "./AbstractMqttManager";

class CustomMqttHelper {

    private custom_mqtt_managers: any[];
    private topics_to_listen_to: any[];

    constructor() {
        this.custom_mqtt_managers = []
        this.topics_to_listen_to = []
    }

    addCustomManager(m: AbstractMqttManager) {
        this.custom_mqtt_managers.push(m)
    }

    getTopicsToListen() {
        const topics: string[] = new Array<string>()
        this.custom_mqtt_managers.forEach((manager: AbstractMqttManager) => {
            topics.push(...AbstractMqttManager.topics_to_listen)
        })
        this.topics_to_listen_to = topics
        return topics
    }

    onMessage(topic: string, msg: any) {
        if (!this.topics_to_listen_to.includes(topic)) {
            return false
        }
        this.custom_mqtt_managers.every((manager: AbstractMqttManager) => {
            if (AbstractMqttManager.topics_to_listen.includes(topic)) {
                manager.onMessage(topic, msg)
                return false
            }
            return true
        })
        return true
    }
}

export {CustomMqttHelper}
