<template>
  <div class="min-h-full  max-h-screen">
    <div class="p-3 color_enabled overflow-x-auto">
      <h3 class="mb-2">Barcode list</h3>
      <table class="w-full border-[1px] border-solid color_enabled table-fixed border_color_offwhite" style="min-width: 500px;">
        <col width="20%" />
        <col width="40%" />
        <col width="40%" />
        <thead>
          <tr>
            <td class="p-1 border-[1px] border-solid color_enabled border_color_offwhite w-50">{{ $t("str_barcode") }}</td>
            <td class="p-1 border-[1px] border-solid color_enabled border_color_offwhite">{{ $t("str_transportation_type") }}</td>
            <td class="p-1 border-[1px] border-solid color_enabled border_color_offwhite">{{ $t("str_remaining_palletes") }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(barcode) in Object.keys(barcodesList)" :key="barcode"> <!-- for each barcode -->
            <td class="p-1 border-[1px] border-solid color_enabled border_color_offwhite w-50">{{ barcode }}</td>
            <td class="p-1 border-[1px] border-solid color_enabled border_color_offwhite">{{ barcodesList[barcode].type }}</td>
            <td class="p-1 border-[1px] border-solid color_enabled border_color_offwhite">{{ barcodesList[barcode].amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <barcode-feedback-modal v-show=barcodeState.new_barcode @closeModal="barcodeState.new_barcode = false" />
  </div>
</template>

<script lang="ts">
import { barcodeState, barcodesList } from '@/customfeatures/knauf/KnaufReactiveState';
import KnaufMqttManager from "@/customfeatures/knauf/KnaufMqttManager";
import BarcodeFeedbackModal from "@/customfeatures/knauf/BarcodeFeedbackModal.vue";

export default {
  name: "BarcodeView",
  components: { BarcodeFeedbackModal },
  data() {
    return {
      barcodeState,
      barcodesList
    }
  }
}
</script>

<style scoped></style>
