const jwt_key = 'jwt_key'
const user = 'user'
const locale_key = 'locale'
const actions_key = 'actions'

import { useConfig } from "@/main";
import { currentUserState } from "./ReactiveStates";

const emptyUserObject = {
  joined_date: '',
  username: '',
  role: {
    permissions: [] as any[],
    name: '',
    id: 1,
    description: '',
    role_level: null as number | null
  },
  name: '',
  email: ''
};

function setJwt(jwt: string) {
  localStorage.setItem(jwt_key, jwt);
}

function getJwt() {
  return localStorage.getItem(jwt_key);
}

function removeJwt() {
  return localStorage.removeItem(jwt_key);
}

function setUser(newUser: any) {
  localStorage.setItem(user, JSON.stringify(newUser));
  currentUserState.username = newUser.username || currentUserState.username;
  currentUserState.email = newUser.email || currentUserState.email;
  currentUserState.name = newUser.name || currentUserState.name;
  currentUserState.role.description = newUser.role.description || currentUserState.role.description;
  currentUserState.role.id = newUser.role.id || currentUserState.role.id;
  currentUserState.role.name = newUser.role.name || currentUserState.role.name;
  currentUserState.role.role_level = newUser.role.role_level; // allowed to set null, so we just assign the new value
  currentUserState.role.permissions = newUser.role.permissions || currentUserState.role.permissions;
  currentUserState.joined_date = newUser.joined_date || currentUserState.joined_date;
}

function getUser() {
  const storedUser = localStorage.getItem(user);
  if (!!storedUser?.length) return JSON.parse(storedUser);
  return {};
}

function removeUser() {
  currentUserState.username = emptyUserObject.username;
  currentUserState.email = emptyUserObject.email;
  currentUserState.name = emptyUserObject.name;
  currentUserState.role.description = emptyUserObject.role.description;
  currentUserState.role.id = emptyUserObject.role.id;
  currentUserState.role.name = emptyUserObject.role.name;
  currentUserState.role.role_level = emptyUserObject.role.role_level;
  currentUserState.role.permissions = emptyUserObject.role.permissions;
  currentUserState.joined_date = emptyUserObject.joined_date;
  return localStorage.removeItem(user);
}

function setLocale(locale:string){
  useConfig().updateLocalConfig({locale});
}

function getLocale(){
  return useConfig().localConfig ? useConfig().localConfig.locale : null;
}

function replacer(key:any, value:any) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
}

function reviver(key: any, value: any) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

function setActions(actions: []) {
    localStorage.setItem(actions_key, JSON.stringify(actions))
}

function getActions() {
    const actions_str = localStorage.getItem(actions_key)
    if (actions_str != null) {
        const actions: any = JSON.parse(actions_str)
        return actions
    } else
        return null
}

function getAction(actionType: string) {
    const actions = getActions()
    return actions.filter((action: any) => action.scope_instant == true && action.actionType === actionType)
}

export {
  setJwt,
  getJwt,
  removeJwt,
  setUser,
  getUser,
  removeUser,
  getLocale,
  setLocale,
  getActions,
  setActions,
  getAction
}
