<!-- 
  UI component to show individual alert. Only responsibility is to display notification data
 -->
<template>
  <div :id="`alert-modal-${alertId}`" role="alert"
       :class="{'stacked-2' : alertStacked == 2, 'stacked-3' : alertStacked && alertStacked > 2, 'relative pointer-events-auto z-20 w-full max-w-sm overflow-visible rounded-lg color_background_light shadow-lg ring-1 ring-black ring-opacity-5': true}">
    <div v-if="(alertStacked && alertStacked > 1)" class="absolute -left-2.5 -top-2.5 w-5 h-5 bg-gray-500 text-white rounded-full text-xs flex justify-center items-center">
      {{ alertStacked }}
    </div>
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <CheckCircleIcon v-if="(alertType==NotificationType.success)" class="h-12 w-12 color_enabled font-thin" aria-hidden="true" style="stroke-width: 0.8" />
          <ExclamationCircleIcon v-else-if="(alertType==NotificationType.warning)" class="h-12 w-12 color_accent font-thin" aria-hidden="true" style="stroke-width: 0.8" />
          <XCircleIcon v-else class="h-12 w-12 color_error_red font-thin" aria-hidden="true" style="stroke-width: 0.8" />
        </div>
        <div class="ml-3 w-auto flex-1 pt-0.5">
          <p class="text-sm font-medium color_enabled">{{ alertTitle }}</p>
          <p class="mt-1 text-sm text-gray-500">{{ alertText }}</p>
        </div>
        <div class="ml-4 flex flex-shrink-0">
          <button type="button" @click="showAlertVar = false"
            class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Close</span>
            <XMarkIconSolid class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {CheckCircleIcon, ExclamationCircleIcon, XCircleIcon} from '@heroicons/vue/24/outline'
import {XMarkIcon as XMarkIconSolid} from '@heroicons/vue/20/solid'</script>

<script lang="ts">

import { defineComponent } from 'vue'
import * as StorageManager from "../datamanagers/StorageManager";
import { prod_safe_log } from '../utils'
import { NotificationType } from '../datamanagers/AlertsManager';

export default defineComponent ({
    name: "AlertModal",
    data() {
        return {
            showAlertVar: true,
        };
    },
    props: {
        alertTitle: {
            type: String,
        },
        alertText: {
            type: String
        },
        alertType: {
            type: Number
        },
        alertId: {
          type: Number
        },
        alertStacked: {
            type: Number
        }
    },
    watch: {
        showAlertVar(newValue, oldValue) {
            if (newValue === false) {
                this.closeModal();
            }
        },
    },
    methods: {
        closeModal() {
          this.$emit("closeModal");
        },
    },
})

</script>
