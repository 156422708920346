import AbstractMqttManager from "@/customfeatures/AbstractMqttManager";
import {publishMqqtMessage} from "@/network/MqttManager";
import {barcodeState, barcodesList, knaufMiddlewareState} from '@/customfeatures/knauf/KnaufReactiveState';

export default class KnaufMqttManager extends AbstractMqttManager {

    constructor(configs: any) {
      super(configs);
    }

    onMessage(topic: string, message: any) {
        super.onMessage(topic, message);
        const json_obj = JSON.parse((`${message}`));
        if (topic === 'emm5050/v2/EmmSolutions/knauf/barcode_list') {
          const savedBarcodes = Object.keys(barcodesList);
          const receivedBarcodes = Object.keys(json_obj);

          // Clean the object from deleted barcodes.
          savedBarcodes.forEach(barcode => {
            if (receivedBarcodes.indexOf(barcode) === -1) {
              delete barcodesList[barcode];
            }
          });

          // Sen new values for barcodes.
          for (let key in json_obj) {
            barcodesList[key] = json_obj[key];
          }
        } else if (topic === 'emm5050/v2/EmmSolutions/knauf/middleware_state') {
          knaufMiddlewareState.state = json_obj.state;
        } else {
          barcodeState.barcode = json_obj.data;
          barcodeState.error_code = json_obj.error;
          barcodeState.quantity = 1;
          barcodeState.new_barcode = true;
        }
    }

    static publishFeedback(configs: any, host: string, protocol: string) {
        const barcodeFeedbackMsg = {vehicle: barcodeState.vehicle, quantity: barcodeState.quantity}
        publishMqqtMessage(configs, host, protocol, KnaufMqttManager.topics_to_publish[0], barcodeFeedbackMsg, 0)
    }
}

