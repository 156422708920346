/**
 * Reactive state for all Nodes
 */

import { reactive } from 'vue';

// state of the current user
export const NodesManager = reactive({
  nodes: new Map<number, any>(),
  lastUpdatedTimestamp: null as null | number, // timestamp of the last made update to request only updated elements from the API
  editedNode: null as null | number, // ID of the edited edge. TODO - store clone of the edge for possibility to revert changes (?).
  add(newNode: any) {
    // console.log('nodesState addNode');
    this.nodes.set(Number(newNode.nodeId), newNode);
    this.lastUpdatedTimestamp = new Date().valueOf();
  },
  delete(nodeId: number) {
    // console.log('nodesState deleteNode');
    this.nodes.delete(nodeId);
    this.lastUpdatedTimestamp = new Date().valueOf();
  },
  update(updatedNode: any) {
    // console.log('nodesState updateNode');
    this.nodes.set(Number(updatedNode.nodeId), updatedNode);
    this.lastUpdatedTimestamp = new Date().valueOf();
  },
  clear() {
    this.nodes.clear();
  },
});
