<template>
  <div class="color_background_light " style="height:92%">
    <div class="my-auto  p-4 h-full ">
      <div>
        <button type="button" @click="() => showFiltersForm = !showFiltersForm"
                class="relative inline-flex items-center rounded-md border px-4 py-2 mx-1 text-sm font-medium color_enabled border_color_offwhite">
          <FunnelIcon class="-mx-1 h-5 w-5" aria-hidden="true"/>
        </button>
      </div>
      <div v-if="showFiltersForm" class="flex flex-col w-[calc(100%-5rem)]" style="min-width: 300px;">
          <div class="color_enabled text-lg text-medium w-full h-9 mb-2 ml-2 items-center flex">{{ $t("str_filter_by") }}:</div>
          <div class="flex flex-col w-1/3 px-2">
            <div class="flex flex-row items-center justify-between mb-2">
              <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40 mt-2">Matching text:</span>
              <div class="max-w-md mb-0">
                <styled-input name="text" class="mt-0 mb-0" type="text" v-model="filters.text"/>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between">
              <span class="color_enabled whitespace-nowrap mr-3 leading-none w-40">{{ $t("str_vehicle_id") }}:</span>
              <Dropdown
                class="max-w-md mb-0"
                style="margin-bottom:0;"
                :elem-list="(Array.from(availableCategories))"
                :prompt="'Select Category'"
                name="vehicle_id"
                v-model="filters.categories"
                @selectedEl="(elem) => filters.categories = elem"
                />
            </div>
            <div class="flex mt-2">
              <button class="mx-1 border w-full rounded-md py-2 text-sm button" @click="clearFilters">{{ $t("str_clear_filters") }}</button>
            </div>
          </div>
      </div>
      <div class="flex flex-col fullscreen justify-between">
        <div class="flex w-auto flex-col pb-16">
          <div class="w-full h-full ">
            <ul role="list"
                class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              <li v-for="route in filteredSavedRoutes" :key="route.routeId" class="relative">
                <quick-route-item :saved-route="route" @orderRequestSelected="confirmOrder" @orderRouteEdit="editOrder"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <order-confirmation-popup v-if="selectedRoute != null" :saved-route="selectedRoute"
                                :vehicles="vehicleStates"
                                @orderConfirmed="(orderConfirmed: boolean, order: any)=> {if (orderConfirmed) submitOrder(order);
                                                                    selectedRoute=null}"/>
    </div>
  </div>
</template>

<script setup lang="ts">

import OrderConfirmationPopup from "@/components/ordertemplate/OrderConfirmationPopup.vue";
</script>


<script lang="ts">
import {defineComponent} from 'vue'
import * as ApiManager from "../network/ApiManager";
import {vehicleStates} from '@/dtos/VehicleState'
import * as AlertManager from '@/datamanagers/AlertsManager'
import Dropdown from '@/components/Dropdown.vue';
import StyledInput from "@/components/StyledInput.vue";
import {FunnelIcon} from '@heroicons/vue/24/outline';
import QuickRouteItem from "@/components/ordertemplate/QuickRouteItem.vue";
import {NodesManager} from "@/datamanagers/NodesManager";

export default defineComponent({
  name: "QuickRoutes",
  data() {
    return {
      selectedRoute: null as unknown as any,
      savedRoutes: [] as any[],
      filteredSavedRoutes: [] as any[],
      nodes: NodesManager.nodes,
      vehicleStates,
      availableCategories: new Set() as Set<String>,
      showFiltersForm: false,
      filters: {
        text: '',
        categories: '' //[] as String[],
      }
    };
  },
  components: {
    QuickRouteItem
  },
  watch: {
    filters:{
      handler(newVal, oldVal) {
        this.filterSavedRoutes();
      },
      deep: true
    }
  },
  methods: {
    getAllOrderTemplates() {
      ApiManager.getAllOrderTemplates(
          () => {},
          (data: []) => {
            this.savedRoutes = data;
            this.clearFilters();
            this.savedRoutes.forEach((route: any) => {
              if (route?.category?.length) {
                route?.category.split(' ').map((category: string) => {
                  this.availableCategories.add(category);
                })
              }
            });
          }
      )
    },
    confirmOrder(route: any) {
      this.selectedRoute = structuredClone(route);
    },
    editOrder(nodes: []) {
      this.selectedRoute = {nodes: nodes, vehicleId: ""}
    },
    submitOrder(order: { nodes: [], vehicleId: string, priority: number }) {
      const nodes = order.nodes.map((value: any) => {
        const actionNode = {
          node_id: value.nodeId,
          default_actions: value.defaultActions,
          actions: []
        }
        if (!value.defaultActions) {
          actionNode.actions = value?.actions?.map((actionVal: any) => {
            return {
              actionId: actionVal.action.actionId,
              actionParameters: actionVal.actionParameters
            }
          }) ?? []
        }
        return actionNode
      })
      ApiManager.createOrderRequest(nodes, new Date().toISOString(), String(order.vehicleId),
          order.priority,
          () => {
          },
          ((id: string) => {
            console.log("Created order request successfully")
          }))
    },
    filterSavedRoutes() {
      const filtered = this.savedRoutes
        .filter((route: any) => {
          const check = !this.filters.text.length || // If there are no text specified - return all elements
            (route.title || `route ${route.orderTemplateId}`).toLowerCase().includes(this.filters.text.toLowerCase() || '') || // check for the matching text in the title
            (route.description || '').toLowerCase().includes(this.filters.text.toLowerCase() || ''); // check for the matching text in the description
          return check;
        })
        .filter((route: any) => {
          if (this.filters.categories?.length && this.filters.categories === 'None'){
            return !route.category?.length
          }
          const check = !this.filters.categories.length || // If there are no category selected - return all elements
            (route.category || '').includes(this.filters.categories || ''); // Check for the matching category
            // TODO - check categories as an array instead of string to use multiple categories.
          return check;
        });

      this.filteredSavedRoutes = filtered;
    },
    clearFilters() {
      this.filters.text = '';
      this.filters.categories = '';
      this.filterSavedRoutes();
    },
  },
  mounted() {
    this.availableCategories.add('None');
  },
  created() {
    ApiManager.subscribeToUpdates([[ApiManager.sse_message_types.order_template_list_updated, () => this.getAllOrderTemplates()]])
    this.getAllOrderTemplates()
  },
})
</script>

<style>
</style>
