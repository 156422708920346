import {reactive} from "vue";
import {getLocale} from "@/datamanagers/StorageManager";

export const liveViewEditorState = reactive({
    editMode: false,
    editingTrajectory: false,
    capturingEndNode: false,
    newOrderOpen: false,
    newOrderData: {
      orderTemplateId: null as null | number,
      nodes: [] as any[],
      title: null as String | null,
      description: null as String | null,
      category: null as String | null
    },
    editedConnectedComponent: null as {id: (number | string | null), edges: number[]} | null,
    locale: getLocale()
});
