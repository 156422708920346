<template>
  <div class="color_background_light " style="height:92%">
    <div class="my-auto p-4 h-full pb-16">
      <h3 class="text-lg font-medium mb-4 color_enabled">{{ $t('str_administration_control_panel') }}</h3>
      <div>
        <h3 class="color_enabled mt-4 mb-1">{{ $t('str_extra_controls') }}:</h3>
        <button class="button2 ml-0 border rounded-lg py-1 px-3 color_background_light" @click="getActionsFromApi">{{
        $t('str_update_actions_from_api') }}</button> <br />
        <button class="button2 ml-0 mt-2 border rounded-lg py-1 px-3 color_background_light"
          @click="getConfigurationFromApi">{{ $t('str_update_configuration_from_api') }}</button>
      </div>
      <h3 class="color_enabled mt-4">{{ $t('str_editors') }}:</h3>
      <button class="button2 ml-0 border rounded-lg p-1 color_background_light"
        @click="switchEditorMode('global_config')">{{ $t('str_open_global_config_editor') }}</button>
      <button class="button2 ml-2 border rounded-lg p-1 color_background_light"
        @click="switchEditorMode('local_config')">{{ $t('str_open_local_config_editor') }}</button>
      <button class="button2 ml-2 border rounded-lg p-1 color_background_light" @click="switchEditorMode('maps')">{{
        $t('str_open_maps_editor') }}</button>
      <div v-if="editorMode === 'global_config'">
        <div>
          <button @click="showLocalConfigLegend = !showLocalConfigLegend"
            class="button2 ml-0 mt-2 border rounded-lg p-1 color_background_light">{{ showLocalConfigLegend ?
        $t('str_hide_legend') : $t('str_show_legend') }}</button>
          <dl v-if="showLocalConfigLegend" class="color_enabled">
            <dt>
              <h3>{{ $t('str_legend') }}:</h3>
            </dt>
            <dt>global_api_request_timeout</dt>
            <dd><small>Number</small> {{ $t('str_global_api_request_timeout_description') }}</dd>
            <dt>nodes_pagination</dt>
            <dd><small>Number</small> {{ $t('str_nodes_pagination_description') }}</dd>
            <dt>edges_pagination</dt>
            <dd><small>Number</small> {{ $t('str_edges_pagination_description') }}</dd>
            <dt>power_saving_available</dt>
            <dd><small>Boolean</small> {{ $t('str_power_saving_available_description') }}</dd>
            <dt>disable_order_deletion</dt>
            <dd><small>Boolean</small> {{ $t('str_disable_order_deletion_description') }}</dd>
          </dl>
        </div>
        <div v-if="isGlobalConfigOutdated" class="deletebutton p-3">{{ $t('str_config_outdated_warning_msg') }}</div>
        <div class="float-right sticky top-2 right-2 z-10">
          <button class="button2 ml-0 border rounded-lg p-1 color_background_light" @click="saveGlobalConfig" :disabled="isGlobalConfigOutdated">{{
        $t('str_apply_global_config') }}</button>
          <button class="deletebutton ml-0 md:ml-2 border rounded-lg p-1 color_background_light"
            @click="revertConfig">{{ $t('str_revert_changes') }}</button>
        </div>
        <h3 class="color_enabled mt-4">{{ $t('str_global_config_editor') }}:</h3>
        <Vue3JsonEditor v-model="globalConfig" :show-btns="false" :expandedOnStart="false" @json-change="onConfigChange"
          class="mt-5 config-editor" />
      </div>
      <div v-if="editorMode === 'local_config'">
        <div class="float-right sticky top-2 right-2 z-10">
          <button class="button2 ml-0 border rounded-lg p-1 color_background_light" @click="saveLocalConfig">{{
        $t('str_apply_local_config') }}</button>
          <button class="deletebutton ml-0 md:ml-2 border rounded-lg p-1 color_background_light"
            @click="revertLocalConfig">{{ $t('str_revert_changes') }}</button>
        </div>
        <div>
          <button @click="showLocalConfigLegend = !showLocalConfigLegend"
            class="button2 ml-0 mt-2 border rounded-lg p-1 color_background_light">{{ showLocalConfigLegend ?
        $t('str_hide_legend') : $t('str_show_legend') }}</button>
          <dl v-if="showLocalConfigLegend" class="color_enabled">
            <dt>
              <h3>{{ $t('str_legend') }}:</h3>
            </dt>
            <dt>locale</dt>
            <dd><small>String</small> {{ $t('str_locale_description') }}</dd>
            <dt>showVehicleLabels</dt>
            <dd><small>Boolean</small> {{ $t('str_showVehicleLabels_description') }}</dd>
            <dt>showNodeLabels</dt>
            <dd><small>Boolean</small> {{ $t('str_showNodeLabels_description') }}</dd>
            <dt>global_api_request_timeout</dt>
            <dd><small>Number</small> {{ $t('str_global_api_request_timeout_description') }}</dd>
            <dt>get_all_orders_timeout</dt>
            <dd><small>Number</small> {{ $t('str_get_all_orders_timeout_description') }}</dd>
            <dt>get_all_order_requests_timeout</dt>
            <dd><small>Number</small> {{ $t('str_get_all_order_requests_timeout_description') }}</dd>
            <dt>get_all_vehicles_timeout</dt>
            <dd><small>Number</small> {{ $t('str_get_all_vehicles_timeout_description') }}</dd>
            <dt>get_nodes_timeout</dt>
            <dd><small>Number</small> {{ $t('str_get_nodes_timeout_description') }}</dd>
            <dt>nodes_pagination</dt>
            <dd><small>Number</small> {{ $t('str_nodes_pagination_description') }}</dd>
            <dt>get_edges_timeout</dt>
            <dd><small>Number</small> {{ $t('str_get_edges_timeout_description') }}</dd>
            <dt>edges_pagination</dt>
            <dd><small>Number</small> {{ $t('str_edges_pagination_description') }}</dd>
            <dt>sse_pulse_interval</dt>
            <dd><small>Number</small> {{ $t('str_sse_pulse_interval_description') }}</dd>
            <dt>base_line_width</dt>
            <dd><small>Number</small> {{ $t('str_base_line_width_description') }}</dd>
            <dt>default_vehicle</dt>
            <dd><small>string</small> {{ $t('str_sefault_vehicle_description') }}</dd>
            <dt>hard_vehicle_restriction</dt>
            <dd><small>boolean</small> {{ $t('str_hard_vehicle_restriction_description') }}</dd>
          </dl>
        </div>
        <h3 class="color_enabled mt-4">{{ $t('str_local_config_editor') }}:</h3>
        <Vue3JsonEditor v-model="localConfig" :show-btns="false" :expandedOnStart="false"
          @json-change="onLocalConfigChange" class="mt-5 config-editor" />
      </div>
      <div v-if="editorMode === 'maps'">
        <h3 class="color_enabled mt-6 mb-2">{{ $t('str_maps_editor') }}:</h3>
        <div>
          <Listbox as="div" v-model="edited_map" class="relative flex-grow z-10">
            <ListboxButton style="height:30px;"
              class="relative l-0 r-0 w-full cursor-default rounded-sm border border-gray-300 bg-white pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
              <span class="block truncate">{{ edited_map?.map_img || 'Select Map to edit' }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
              leave-to-class="opacity-0">
              <ListboxOptions
                class="absolute inset-x-0 mt-1 max-h-60 overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ListboxOption as="template" v-for="map in globalConfig.configuration.maps" :key="map" :value="map"
                  v-slot="{ active, selected }" @click="setEditedMap">
                  <li
                    :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ map.map_img
                      }}</span>
                    <span v-if="selected"
                      :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </Listbox>
          <div class="flex mt-2 min-h-[400px]">
            <div class="flex-1">
              <Vue3JsonEditor v-if="edited_map" v-model="edited_map" :show-btns="false" :expandedOnStart="true"
                mode="form" @json-change="onMapPropChange" class="config-editor" />
              <button v-if="edited_map" class="button2 ml-0 mt-2 border rounded-lg p-1 color_background_light"
                @click="saveMapChanges">{{ $t('str_save_map_configuration') }}</button>
            </div>
            <div class="flex-1 pl-2 relative">
              <button class="absolute top-1 left-3" @click="redrawMapPreview">
                <ArrowPathIcon class="h-5 w-5 text-gray-500" />
              </button>
              <canvas id="edited_map_canvas" class="w-full bg-red-50"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ArrowPathIcon, CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {Vue3JsonEditor} from 'vue3-json-editor';
import * as AlertManager from '@/datamanagers/AlertsManager';
import * as ApiManager from '@/network/ApiManager';
import {prod_safe_log} from '../utils';
import * as StorageManager from "../datamanagers/StorageManager";
import {useConfig} from "@/main";

export default defineComponent({
  name: "AdministrationView",
  data() {
    return {
      editorMode: '',
      globalConfig: JSON.parse(JSON.stringify(useConfig().config)),
      localConfig: JSON.parse(JSON.stringify(useConfig().localConfig)),
      edited_map: null as any,
      previewCanvas: null as any,
      mapPreviewContext: null as CanvasRenderingContext2D | null,
      bosch_map_preview_img: null as HTMLImageElement | null,
      map_preview_img: null as HTMLImageElement | null,
      showLocalConfigLegend: false,
      isGlobalConfigOutdated: false
    };
  },
  watch: {
  },
  components: {
    Vue3JsonEditor,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    ArrowPathIcon,
  },
  methods: {
    switchEditorMode(newMode: string) {
      if (this.editorMode === newMode) return;
      this.edited_map = null;
      this.revertConfig();
      this.editorMode = newMode;
    },
    checkGlobalConfigVersion() {
      ApiManager.getLatestConfigurationVersion(
        (err: any) => {console.log('API error', err)},
        (response: any) => {
          if (useConfig().globalConfigVersion < response?.data?.version) {
            console.log('Configs are outdated, download latest to proceed.');
            this.isGlobalConfigOutdated = true;
          }
        });
    },
    saveGlobalConfig() {
      if (this.isGlobalConfigOutdated) return;
      useConfig().setConfig(JSON.parse(JSON.stringify(this.globalConfig)));
      ApiManager.saveConfiguration({ config_file: JSON.stringify(this.globalConfig), filename: `configuration` },
      (err: any) => { console.log(err) },
      (result: any) => {
        console.log(result?.data?.message);
        if (result.data.configs.id) {
          useConfig().setConfigVersion(result.data.configs.id)
        }
      });
      // TODO - Refresh page on config change to apply new parameters
    },
    saveLocalConfig() {
      useConfig().setLocalConfig(JSON.parse(JSON.stringify(this.localConfig)));
    },
    revertConfig() {
      this.globalConfig = JSON.parse(JSON.stringify(useConfig().config));
    },
    onConfigChange(newConfig: any) {
      this.globalConfig = newConfig;
    },
    revertLocalConfig() {
      this.localConfig = JSON.parse(JSON.stringify(useConfig().localConfig));
    },
    onLocalConfigChange(newConfig: any) {
      this.localConfig = newConfig;
      console.log(this.localConfig)
    },
    setEditedMap() {
      const tempMapReference = this.edited_map;
      this.edited_map = null;
      setTimeout(() => {
        this.edited_map = tempMapReference;

        this.previewCanvas = document.getElementById('edited_map_canvas') as HTMLCanvasElement;
        this.mapPreviewContext = this.previewCanvas.getContext('2d');
        // @ts-ignore
        this.map_preview_img = (() => {
          const mapObject = new Image();
          mapObject.src = `api/2.0/map_configs/map_image/${this.edited_map.map_img}`;
          return mapObject;
        })();
        // @ts-ignore
        this.bosch_map_preview_img = (() => {
          const mapObject = new Image();
          mapObject.src = `api/2.0/map_configs/map_image/${this.edited_map.bosch_map}`;
          return mapObject;
        })();
        this.redrawMapPreview();
        // TODO - check if there are unsaved changes and show a popup if any
        // @ts-ignore
        this.map_preview_img.addEventListener('load', (event: any) => this.redrawMapPreview());
        // @ts-ignore
        this.bosch_map_preview_img.addEventListener('load', (event: any) => this.redrawMapPreview());
      }, 0)
    },
    onMapPropChange(newMapObj: any) {
      this.edited_map = newMapObj;
      // TODO - Map editor feature:
      // - upload map image
    },
    getActionsFromApi() {
      console.log('get actions from the API');
      ApiManager.getActions(
        () => {
          // @ts-ignore
          AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_actions"), this.$t("str_try_again")))
          prod_safe_log('actions could not be requested')
        },
        (data: any) => {
          if (data?.actions)
            StorageManager.setActions(data.actions)
        });
    },
    getConfigurationFromApi() {
      console.log('get configuration from the API');
      ApiManager.getLatestConfiguration(
        () => {
          // @ts-ignore
          AlertManager.showAlert(AlertManager.createErrorAlert('There was error getting latest configuration from the API', this.$t("str_try_again")))
          prod_safe_log('configuration could not be requested')
        },
        (data: any) => {
          if (data) {
            this.globalConfig = JSON.parse(data.data.configs.value);
            useConfig().setConfigVersion(data.data.configs.id);
            useConfig().setConfig(JSON.parse(JSON.stringify(this.globalConfig)));
          }
        });
    },
    redrawMapPreview() {
      if (!this.mapPreviewContext || !this.edited_map) return;
      const map = this.map_preview_img;
      const bosch_map = this.bosch_map_preview_img;
      if (!map || !bosch_map) return;
      const canvas_width = this.previewCanvas.getBoundingClientRect().width;
      const canvas_height = this.previewCanvas.getBoundingClientRect().height;
      let scale;
      if (map.naturalWidth > map.naturalHeight) {
        scale = map.naturalWidth / canvas_width;
      } else {
        scale = map.naturalHeight / canvas_height;
      }
      this.previewCanvas.width = map.naturalWidth / scale;
      this.previewCanvas.height = map.naturalHeight / scale;
      this.mapPreviewContext.save();
      this.mapPreviewContext.clearRect(0, 0, canvas_width, canvas_height);
      // @ts-ignore
      this.mapPreviewContext.drawImage(map, 0, 0, canvas_width, canvas_height);
      this.mapPreviewContext.scale(this.edited_map.map_scale[0], this.edited_map.map_scale[1]); //scale map coordinate
      this.mapPreviewContext.rotate((Math.PI / 180) * this.edited_map.map_rotate); //rotate map coordinate
      this.mapPreviewContext.scale(1, this.edited_map.scanner_reversion_factor); // flip map coordinate
      this.mapPreviewContext.translate(this.edited_map.map_translate[0] * (canvas_height / 500), this.edited_map.map_translate[1] * (canvas_height / 500)); // translate map corrdinate
      // @ts-ignore
      this.mapPreviewContext.drawImage(bosch_map, 0, 0, bosch_map.naturalWidth / scale, bosch_map.naturalHeight / scale);//bosch map over the sim map
      // translate to map center
      var map_center: number[];

      const configuredCenterX = (-1) * this.edited_map.map_center[0] / this.edited_map.map_real_size[0];
      const configuredCenterY = (-1) * this.edited_map.map_center[1] / this.edited_map.map_real_size[1];

      if (this.edited_map.scanner_reversion_factor == 1) {
        map_center = [configuredCenterX, 1 - configuredCenterY];
      } else {
        map_center = [1 - configuredCenterX, 1 - configuredCenterY];
      }
      this.mapPreviewContext.translate(map_center[0] * (bosch_map.naturalWidth / scale), map_center[1] * (bosch_map.naturalHeight / scale));

      this.mapPreviewContext.fillStyle = 'red';
      this.mapPreviewContext.beginPath();
      this.mapPreviewContext.arc(0, 0, 5, 0, 2 * Math.PI);
      this.mapPreviewContext.fill();
      this.mapPreviewContext.restore();
    },
    saveMapChanges() {
      const editedMapIndex = this.globalConfig.configuration.maps.findIndex((map: any) => map.map_id == this.edited_map.map_id);
      if (editedMapIndex > -1) {
        this.globalConfig.configuration.maps[editedMapIndex] = JSON.parse(JSON.stringify(this.edited_map));
        this.saveGlobalConfig();
      }
    },
    // TODO - Import/export:
    // - config from API (restore last stored one)
    // - Import config from file (.yml)
    // - Export config to file
  },
  mounted() {
  },
  created() {
    this.checkGlobalConfigVersion();
  },
})
</script>

<style>
.config-editor div.jsoneditor {
  border: none;
  background: #1f2936;
  color: #F8F2DC;
  border-radius: .75rem;
}

.config-editor .jsoneditor-vue .jsoneditor-outer {
  min-height: 300px;
}

.config-editor div.jsoneditor-menu {
  padding: 0.75rem 1.5rem;
  background: transparent;
  border: none;
  height: auto;
  color: #F8F2DC;
  display: flex;
}

.config-editor table.jsoneditor-search {
  top: .75rem;
  right: .75rem;
}

.config-editor div.jsoneditor-field {
  color: #F8F2DC;
}

.config-editor div.jsoneditor-field[contenteditable=true]:focus,
.config-editor div.jsoneditor-field[contenteditable=true]:hover,
.config-editor div.jsoneditor-value[contenteditable=true]:focus,
.config-editor div.jsoneditor-value[contenteditable=true]:hover,
.config-editor div.jsoneditor-field.jsoneditor-highlight,
.config-editor div.jsoneditor-value.jsoneditor-highlight {
  background: rgba(255, 255, 255, 0.15);
  border: none
}

.config-editor div.jsoneditor textarea {
  resize: vertical;
  min-height: 265px;
}

.config-editor .ace-jsoneditor.ace_editor {
  min-height: 400px;
}

.config-editor div.jsoneditor td.jsoneditor-tree {
  vertical-align: middle;
}

.config-editor tr.jsoneditor-highlight,
.config-editor tr.jsoneditor-selected {
  background: rgba(255, 255, 255, 0.3);
}

.config-editor div.jsoneditor-tree button:focus {
  background-color: rgb(150, 150, 150);
  outline: none;
}

a[href="http://ace.ajax.org"] {
  display: none !important;
}

.config-editor div.jsoneditor-value.jsoneditor-object,
.config-editor div.jsoneditor-value.jsoneditor-array {
  color: #819595;
}

.config-editor div.jsoneditor-menu>button.jsoneditor-redo:disabled {
  background-position: -48px -96px;
  opacity: 0.3;
}

.config-editor div.jsoneditor-menu>button.jsoneditor-undo:disabled {
  background-position: -24px -96px;
  opacity: 0.3;
}

dt{
  margin-top:10px;
}

dd {
  font-size: 0.8em;
  padding-left: 0.5rem;
  margin-bottom: 5px;
}

dd small{
  font-style: italic;
}
</style>
