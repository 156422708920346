<template>
  <!-- Barcode filter modal. Displays barcode feedback options -->
  <div id="barcode-feedback-modal"
       class="fixed py-32 pb-64 z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full">
    <!-- Modal body -->
    <main class="content-center mx-auto color_background_light w-9/12 lg:w-6/12 min-h-full px-4 py-2 rounded-lg">
      <div class="flex justify-between">
        <div class="flex flex-grow"/>
        <button type="button" @click="closeModal"
                class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span class="sr-only">Close</span>
          <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
        </button>
      </div>
      <ul role="list" style="text-align: center;">
        <li class="pt-10">
          <b v-if="barcodeState.error_code==0" class="color_enabled text">{{ $t("str_barcode_modal_header") }}</b>
          <b v-else class="color_enabled text">{{ $t("str_barcode_error") }}</b>
        </li>

        <li v-if="barcodeState.error_code==0" class="my-16"/>
        <li v-if="barcodeState.error_code==0" class="pt-4 font-light" style="font-size: 30px;">
          <p class="color_enabled"> {{ barcodeState.barcode }} </p>
        </li>

        <li v-if="barcodeState.error_code==0" class="pt-4 ">
          <p class="color_disabled font-thin px-8">{{ $t("str_barcode_modal_sub_header") }}</p>
        </li>
        <li v-if="barcodeState.error_code==0" class="pt-4">
          <p class="mx-5 mb-2">
            <input style="width:40%; text-align: center;" type="number" step="1" min="1"
                   v-model="barcodeState.quantity">
          </p>
        </li>
        <li v-else class="pt-4 font-light my-16" style="font-size: 30px;">
          <p v-show="barcodeState.error_code==1" class="color_enabled">{{ $t("str_error_text[1]") }}</p>
          <p v-show="barcodeState.error_code==2" class="color_enabled">{{ $t("str_error_text[2]") }}</p>
        </li>
        <br>
        <br>
        <li class="flex pt-4">
          <button
              class="button2 border w-5/12 mx-4 px-4 py-2 text-lg font-light color_enabled rounded"
              style="text-align: center;"
              v-on:click="barcodeState.vehicle='forklift'; sendBarcodeFeedback()">
            {{ $t("str_forklift") }}
          </button>
          <div class="flex flex-grow"/>
          <button
              class="button border w-5/12 mx-4 px-4 py-2 text-lg font-light rounded"
              style="width:40%; text-align: center;"
              v-on:click="barcodeState.vehicle='agv'; sendBarcodeFeedback()">
            {{ $t("str_barcode_option_agv") }}
          </button>
        </li>

      </ul>
            <br>
          </main>
      </div>
</template>

<script setup lang="ts">
import {XMarkIcon} from '@heroicons/vue/20/solid'</script>

<script lang="ts">
import {defineComponent, watch} from 'vue'
import {barcodeState, knaufMiddlewareState} from '@/customfeatures/knauf/KnaufReactiveState'
import KnaufMqttManager from "@/customfeatures/knauf/KnaufMqttManager";
import {useConfig} from "@/main";

export default defineComponent({
  name: "BarcodeFeedbackModal",
  data() {
    return {
      barcodeState,
      error_code: {
        1: "couldn't scan the barcode",
        2: "labels on different sides do not match",
        0: "no errors"
      } 
    }
  },

  methods: {
    closeModal(){
      this.$emit('closeModal');
    },
    sendBarcodeFeedback() {
      KnaufMqttManager.publishFeedback(useConfig().config.configuration.mqtt,
          window.location.hostname,
          window.location.protocol)
      this.closeModal()
    },
  },
  mounted() {
    watch(knaufMiddlewareState, (newState) => {
      if (!newState.state || newState.state !== "WAIT_FOR_CLASSIFICATION"){
        console.log('Received or cancelled Classification response. Closing modal');
        this.closeModal();
      }
    });
  }
})

</script>
