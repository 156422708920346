export class ActionTypes {
    public static CANCEL_ORDER = "cancelOrder"
    public static FACTSHEET_REQUEST = "factsheetRequest"
    public static START_PAUSE = "startPause"
    public static STOP_PAUSE = "stopPause"
    public static START_CHARGING = "startCharging"
    public static STOP_CHARGING = "stopCharging"
    public static INIT_POSITION = "initPosition"
    public static LOG_REPORT = "logReport"
    public static PICK = "pick"
    public static DROP = "drop"
    public static DETECT_OBJECT = "detectObject"
    public static FINE_POSITIONING = "finePositioning"
    public static WAIT_FOR_TRIGGER = "waitForTrigger"
    public static STATE_REQUEST = "stateRequest"
}
