import {distToSegment} from '@/utils'
import {LiveViewHelper} from '@/helpers/LiveViewHelper'

class TrajectoryData {
    controlPoints: number[] = [];
    inputPoints: Array<{ x: number; y: number }> = []

    private _fittedPoints: Array<{ x: number; y: number; }> = [];
    public get fittedPoints(): Array<{ x: number; y: number; }> {
        return this._fittedPoints;
    }

    isDragingPoint: Boolean = false
    draggingPointId: number = -1
    nurbsCurveVda: {
        degree: number,
        knotVector: number[],
        controlPoints: {x: number, y: number, weight: number}[]
    } = null as unknown as any
    nurbsCurve: any;
    auxpoints: {start:{x: number, y: number}[], end:{x: number, y: number}[]} = {start:[] as {x: number, y: number}[], end:[] as {x: number, y: number}[]}

    constructor() {
        this.inputPoints=[]
        this._fittedPoints=[]
    }

    initTrajectory(p1: { x: number, y: number }, p2: { x: number, y: number }) {
        this.deleteTrajectory()
        this.inputPoints.push(p1)
        this.inputPoints.push(p2)
    }

    addPoint(p: { x: number, y: number }) {
        var curr = this.inputPoints[0]
        var minDist = null
        var index = 0
        for (var i = 0; i < this.inputPoints.length - 1; i++) {
            var next = this.inputPoints[i + 1]
            const dist = distToSegment(p, curr, next)
            if ((minDist == null || dist < minDist)) {
                minDist = dist
                index = i
            }
            curr = next
        }
        this.inputPoints.splice(index + 1, 0, p)
    }

    resetAuxPoints(){
        this.auxpoints.start = []
        this.auxpoints.end = []
    }
    addAuxPoint(p:{ x: number, y: number }, start:boolean){
        if ( p == null) return 
        if (start){
            this.auxpoints.start.push(p)
        } else{
            this.auxpoints.end.push(p)
        }
    }

    deletePoint(pointId: number) {
        this.inputPoints.splice(pointId, 1)
    }

    getRawTrajectory() {
        if (this.auxpoints.start.length>0 && this.auxpoints.end.length>0)
            return [...this.auxpoints.start,...this.inputPoints,...this.auxpoints.end]
        else if (this.auxpoints.start.length>0 )
            return [...this.auxpoints.start,...this.inputPoints]
        else if (this.auxpoints.end.length > 0)
            return [...this.inputPoints, ...this.auxpoints.end]
        else return this.inputPoints
    }

    addCurve(curve: any) {
        this._fittedPoints = []
        this.nurbsCurve = curve
        let length
        // if (this.inputPoints.length === 2) {
        //   // console.log(this.inputPoints);
        //   length = 0.02 // If there are only two inut points we use less points
        // } else 
        if (this.inputPoints.length > 0) {
          length = this.inputPoints.length
        } else {
          length = 1
        }
        const step = 0.01 / length
        for (let i = 0; i < 1 + step; i += step) {
            let p = curve.evaluate([], Number(i))
            this._fittedPoints.push({x: p[0], y: p[1]})
        }
    }

    deleteTrajectory(){
        this.inputPoints = []
        this._fittedPoints = []
    }
    removeCurve() {
        this._fittedPoints = this.inputPoints
    }

    isMouseOverPoint(p: { x: number, y: number }, threshold:number) {
        if (this?.inputPoints?.length == null) {
            return -1
        } else {
            // @ts-ignore
            return LiveViewHelper.isMouseOver(p, this?.inputPoints, threshold)?.at(0) ?? -1
        }
    }

    isMouseOverTrajectory(p: { x: number, y: number }, threshold:number) {
        // @ts-ignore
        return LiveViewHelper.isMouseOver(p, this?._fittedPoints, threshold).length > 0
    }

    movePoint(id: number, p: { x: number, y: number }) {
        if (id !== -1) {
            this.inputPoints[id] = p
        }
    }
}

export {TrajectoryData}
