<template>
  <div class="mx-auto w-full color_background_dark rounded-xl  transition-all hover:shadow hover:shadow-gray-500 hover:bg-gray-900 hover:bg-opacity-40 active:bg-gray-900 active:bg-opacity-70  active:shadow-none active:opacity-70">
    <main class="my-4 relative">
      {{}}
      <div class="absolute m-auto right-0">
        <div class="flex-row">
          <OptionsDropdown :options="[
            {title: $t('str_order_templates_edit'), handler: () => editingMeta = true},
            {title: 'Edit Route', handler: () => editOrderTemplate(savedRoute)},
            {title: 'Clone Route', handler: () => confirmCloning(() => cloneOrderTemplate(savedRoute) )},
            {title: $t('str_order_templates_delete'), handler: () => confirmDeletion(() => deleteOrderTemplate(savedRoute.orderTemplateId) )},
          ]"></OptionsDropdown>
        </div>
      </div>
      <div class="button2 flex-col px-4 py-4" @click="handleOrderClick">
        <p v-show="!editingMeta" class="color_enabled text-xl text-left mb-2 pr-8 flex items-center">
          <ExclamationTriangleIcon v-if="!checkRouteValidity() && !savedRoute.criticalError" class="mt-1 mr-1 w-5 h-5 text-orange-500"/>
          <NoSymbolIcon v-if="!checkRouteValidity() && savedRoute.criticalError" class="mr-1 w-5 h-5 text-red-500"/>
          {{ !!savedRoute.title ? savedRoute.title : `Route ${savedRoute.orderTemplateId}` }}
        </p>
        <p v-show="!editingMeta && !!savedRoute.description" class="color_enabled text-sm text-left mb-2">
          {{ savedRoute.description }}
        </p>
        <p v-show="!editingMeta && !!savedRoute.category" class="color_enabled text-m text-left mb-2">
          Categories:
          <div class="flex flex-wrap">
            <div class="border-2 border-white mr-1 px-1 mb-1 rounded-md" v-for="category in ((savedRoute.category || '').split(' '))">{{ category }}</div>
          </div>
        </p>
        <form autocomplete="off" v-show="editingMeta" @submit="(e:any)=>{e.preventDefault(); editRoute(form)}" @reset="(e:any)=>{e.preventDefault(); resetForm()}">
          <StyledInput class="text-gray-900" v-model="form.title" placeholder="Title" :floating="true" type="text" name="savedOrderTitle"/>
          <StyledInput class="text-gray-900" v-model="form.description" placeholder="Decrtiption" :floating="true" type="text" name="savedOrderTitle"/>
          <StyledInput class="text-gray-900" v-model="form.category" placeholder="Category" :floating="true" type="text" name="savedOrderTitle"/>
          <div class="flex -mt-2 mb-2">
            <button class="prevent-popup border w-1/2 mr-1 rounded-md py-1 button2">Submit</button>
            <button class="prevent-popup border w-1/2 ml-1 rounded-md py-1 button2" type="reset">Cancel</button>
          </div>
        </form>
        <ul role="list" class="mx-auto">
          <li>
            <div class="relative pb-2">
              <span v-if="savedRoute.nodes.length !== 1"
                    class="absolute top-0 left-4 -ml-px h-full border-l-2 border-solid border-l-gray-400"
                    aria-hidden="true"/>
              <div class="relative flex space-x-3">
                <div>
                <span
                    :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                    <div v-if="savedRoute?.nodes[0]?.node !== null">
                      <component v-if="savedRoute.nodes.length !== 1" :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                      <component v-if="savedRoute.nodes.length === 1"
                                :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                                aria-hidden="true"/>
                    </div>
                    <ExclamationTriangleIcon v-if="savedRoute?.nodes[0]?.node === null" class="w-5 h-5 text-orange-500"/>
                </span>
                </div>
                <div class="flex min-w-0 space-x-4">
                  <div>
                    <p :class="[ savedRoute?.nodes[0]?.node === null ? 'text-gray-500' : 'color_enabled', 'text-xl text-left flex items-center']">
                      {{ savedRoute?.nodes[0]?.nodeId }}: {{ NodesManager.nodes.get(savedRoute?.nodes[0]?.nodeId)?.nodeDescription }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li v-if="savedRoute?.nodes?.length > 2">
            <div class="relative mb-2">
              <span class="absolute top-0 left-4 -ml-px h-full border-l-2 border-dotted border-l-gray-400"
                    aria-hidden="true"/>
              <div class="relative flex min-w-0 space-x-4 py-1.5 ml-8">
                <p class="text-gray-400 text-xl text-left">
                  {{ `... ${savedRoute?.nodes?.length - 2} ${$t('str_more')}` }}
                </p>
              </div>
            </div>
          </li>
          <li v-if="savedRoute.nodes.length !== 1">
            <div class="relative pb-2">
              <span class="absolute bottom-full left-4 -ml-px h-[20%] border-l-2 border-solid border-l-gray-400"
                    aria-hidden="true"/>
              <div class="relative flex space-x-3">
                <div>
                <span :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                  <div v-if="savedRoute?.nodes[savedRoute?.nodes.length - 1]?.node !== null">
                      <component :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                                 aria-hidden="true"/>
                    </div>
                    <ExclamationTriangleIcon v-if="savedRoute?.nodes[savedRoute?.nodes.length - 1]?.node === null" class="w-5 h-5 text-orange-500"/>
                </span>
                </div>
                <div class="flex min-w-0 space-x-4">
                  <div>
                    <p :class="[savedRoute?.nodes[savedRoute?.nodes.length - 1]?.node === null ? 'text-gray-500' : 'color_enabled', 'text-xl text-left flex items-center']">
                      {{ savedRoute?.nodes[savedRoute?.nodes.length - 1]?.nodeId }}: {{ NodesManager.nodes.get(savedRoute?.nodes[savedRoute?.nodes.length - 1]?.nodeId)?.nodeDescription }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </main>
  </div>
  <DialogPopup ref="dialogPopup"></DialogPopup>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon, StopIcon} from '@heroicons/vue/24/outline'
import OrderTemplateMenu from "@/components/ordertemplate/OrderTemplateMenu.vue";
import OptionsDropdown from '@/components/OptionsDropdown.vue';
</script>

<script lang="ts">
import {defineComponent} from "vue";
import * as ApiManager from "@/network/ApiManager"
import { ExclamationTriangleIcon, NoSymbolIcon } from '@heroicons/vue/24/solid';
import * as AlertManager from "@/datamanagers/AlertsManager";
import {updateOrderTemplate} from "@/network/ApiManager";
import DialogPopup from '@/components/DialogPopup.vue';
import StyledInput from '../StyledInput.vue';
import { NodesManager } from '@/datamanagers/NodesManager';
import { liveViewEditorState } from '@/dtos/AppState';

export default defineComponent({
  name: "QuickRouteItem",
  emits: ['orderRequestSelected', 'orderRouteEdit'],
  data() {
    return {
      editingRoute: false,
      editingMeta: false,
      form: {
        title: this.savedRoute.title || '',
        description: this.savedRoute.description || '',
        category: this.savedRoute.category || '',
      }
    }
  },
  props: {
    savedRoute:
      {
        //  {nodes: [{nodeId: String}]}
        type: null,
        default: null
      }
  },
  watch: {
    savedRoute(newV, oldV) {
      console.log("Saved route", newV)
    },
  },
  computed: {},
  methods: {
    async confirmDeletion(callback: Function) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        //@ts-ignore
        title: this.$t("str_confirm_order_template_deletion_title"),
        //@ts-ignore
        message: this.$t("str_confirm_order_template_deletion_message"),
        //@ts-ignore
        okButton: this.$t("str_confirm_order_template_deletion_btn_delete"),
        //@ts-ignore
        cancelButton: this.$t("str_confirm_order_template_deletion_btn_cancel"),
      });
      if (confirmed) {
        callback();
      } else {
        console.log('Cancelled');
      }
    },
    async confirmCloning(callback: Function) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        title: 'Are you sure you want to create duplicate of the selected Route?',
        message: 'Selected route will not be affected.',
        okButton: 'Clone',
        okButtonType: 'button',
        cancelButton: 'Cancel',
      });
      if (confirmed) {
        callback();
      } else {
        console.log('Cancelled');
      }
    },
    formatActionsForActionsView(actions: any[]) {
      return actions?.map((obj: any) => {
        obj.action = {actionId: obj.actionId}
        const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
            map.set(obj.key, obj), new Map()).values()];
        obj.action.predefined_action = true
        return {action: obj.action, actionParameters: mergedParamArray}
      }) ?? []
    },
    editRoute(payload: {title?: string, description?: string, category?: string, }) {
      console.log("Submitted new template data", payload);

      ApiManager.updateOrderTemplate(this.savedRoute.orderTemplateId, payload, () => {
      }, () => {
        this.editingMeta = false;
        console.log('Edited order template ', this.savedRoute.orderTemplateId)
        this.savedRoute.title = payload.title;
        this.savedRoute.description = payload.description;
        this.savedRoute.category = payload.category;
      })
    },
    resetForm() {
      this.form = {
        title: this.savedRoute.title || '',
        description: this.savedRoute.description || '',
        category: this.savedRoute.category || '',
      };
      this.editingMeta = false;
    },
    handleOrderClick(event: MouseEvent) {
      if((event.target as HTMLElement).nodeName !== "INPUT" && !(event.target as HTMLElement).classList.contains('prevent-popup')) {
        this.$emit('orderRequestSelected', this.savedRoute);
      }
    },
    checkRouteValidity() {
      let isValid = true;
      this.savedRoute.nodes.map((node: any) => {
        if (node.node === null)
          isValid = false;
      });
      return isValid;
    },
    deleteOrderTemplate(orderTemplateId: string) {
      ApiManager.deleteOrderTemplate(orderTemplateId, () => {
        }, () => {
          console.log('Deleted order template ', orderTemplateId)
        })
    },
    editOrderTemplate(orderTemplate: any) {
      const clonedTemplate = structuredClone(orderTemplate)

      clonedTemplate.nodes = clonedTemplate.nodes.map((node: any) => {
        const nodeToAdd = {
          defaultActions: node.defaultActions,
          nodeId: node.nodeId,
          nodeDescription: node.nodeDescription,
        } as any;
        if (!node.defaultActions){
          nodeToAdd.actions = structuredClone(node.actions);
        }
        return nodeToAdd;
      })

      liveViewEditorState.newOrderOpen = true;
      liveViewEditorState.newOrderData = clonedTemplate;

      this.$router.push('/');
    },
    cloneOrderTemplate(orderTemplate: any) {
      if (!this.checkRouteValidity())
        return AlertManager.showAlert(AlertManager.createErrorAlert('Route has missing nodes.', 'Please fix the route errors and try again'));

      const clonedTemplate = structuredClone(orderTemplate);
      delete clonedTemplate.orderTemplateId;

      this.editOrderTemplate(clonedTemplate);
    }
  }
})


</script>

<style scoped>
</style>
