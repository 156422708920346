// state of the barcode scanning
import {reactive} from "vue";

export const barcodeState = reactive({
    barcode: '',
    error_code: 0,
    vehicle: '',
    quantity: 1,
    new_barcode: false
});

export const barcodesList: {[key:string] : any} = reactive({});

export const knaufMiddlewareState: {state?: string | undefined | null} = reactive({});
