<template>
  <!-- New node modal. Creates new node -->
  <div id="new-node-modal" class="z-5 mx-auto flex-col h-full w-full">
    <main class="color_background_dark mx-6">
      <ul role="list">
        <li class="flex flex-row  pt-4 ">
          <p class="color_disabled -ml-2 text-sm uppercase">{{ $t("str_edit_node") }}</p>
          <p v-show="nodeId!==-1 && nodeId!== undefined" class="color_disabled ml-2 text-sm uppercase">{{ `(${nodeId})` }}</p>
          <div class="flex flex-grow"/>
          <button type="button" @click="createNodeOnPosition"
                  class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <PlusIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
          <button type="button" @click="loadPrevNode" :disabled="nodeIndexInStack === 0"
                  class="rounded-md text-gray-400 hover:text-gray-500 disabled:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <ChevronLeftIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
          <button type="button" @click="loadNextNode" :disabled="nodeStack.length === nodeIndexInStack+1"
                  class="rounded-md text-gray-400 :hover:text-gray-500 disabled:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <ChevronRightIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
          <button type="button" @click="closeModal"
                  class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="mb-1 color_enabled w-full">
            {{ $t("str_node_description") }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <div class="mb-1 w-full">
            <input class="w-full rounded" v-model="addNodeForm.nodeDescription">
          </div>
        </li>
        <p class="color_disabled -ml-2 text-sm uppercase pt-2">{{ $t("str_node_position") }}:</p>
        <li class="grid grid-cols-3 mb-2">
          <div>
            <p class=" mb-1 color_enabled">
              x
            </p>
            <input class="mb-1 mr-4 w-3/4 rounded" v-model="addNodeForm.nodePosition.x">
          </div>
          <div>
            <p class="mb-1  color_enabled">
              y
            </p>
            <input class="mb-1 mr-4 w-3/4 rounded" v-model="addNodeForm.nodePosition.y">
          </div>
          <div>
            <p class="mb-1  color_enabled">
              theta
            </p>
            <input class="mb-1 mr-4 w-3/4 rounded" v-model="addNodeForm.nodePosition.theta">
          </div>
        </li>
        <li>
          <ArrowControls :mapId="addNodeForm.nodePosition.mapId" @deltaChanged="processArrowClick"/>
        </li>
        <p class=" color_disabled -ml-2 mb-1 text-sm uppercase pt-2">{{ $t("str_use_vehicle_location") }}</p>
        <li class="">
          <div class="flex flex-row flex-grow justify-between">
            <VehicleDropdown class="flex w-full rounded center"
                             :vehicles="vehicleStates"
                             @vehicleSelected="setVehicleId"
                             :show-automatic="false"
                             :label="$t('str_select_vehicle')"/>
          </div>
          <div class="flex flex-row flex-grow my-4 mx-8">
            <button :disabled="vehicle_id===-1 || (vehicleStates?.get(vehicle_id)?.connected===false ?? false)"
                    class="border w-full rounded-md py-1 button2"
                    @click="read_vehicle_position()">
              {{ $t("str_read_location") }}
            </button>
          </div>

        </li>
        <p class=" color_enabled">{{ $t("str_allowed_deviation") }}</p>
        <li class="flex grid-cols-2">
          <div>
            <p class="mb-1  color_enabled">
              x, y
            </p>
            <input class=" mb-1  w-3/4 rounded" v-model="addNodeForm.nodePosition.allowedDeviationXY">
          </div>
          <div>
            <p class="mb-1  color_enabled">
              theta
            </p>
            <input class="mb-1  w-3/4 rounded" v-model="addNodeForm.nodePosition.allowedDeviationTheta">
          </div>
        </li>
        <li class="flex flex-row flex-grow">
          <p class=" mb-1  color_enabled">
            {{ $t("str_map") }}:
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="w-full mb-2 ">
            <input class="w-full rounded" v-model="addNodeForm.nodePosition.mapId">
          </p>
        </li>
        <li class="flex flex-row flex-grow mx-8 my-2">
          <button @click="unblockNode" class="border w-full rounded-md p-1 button2">
            {{ $t("str_unblock_node") }}
          </button>
        </li>
        <!-- only for existing nodes -->
        <li v-show="nodeId!==-1 && nodeId!== undefined">
          <div class="flex-col w-full ">
            <p class=" color_disabled -ml-2 text-sm uppercase py-1">
              {{ $t("str_connected_edges") }}:
            </p>

            <div class="flex flex-row" v-for="edge in connectedEdges">
              <p class=" place-self-center color_enabled">{{ $t("str_edge") }} {{ edge?.edgeId || '-' }}:
                {{ $t("str_node") }} {{ edge?.startNodeId }} -> {{ $t("str_node") }} {{ edge?.endNodeId }}</p>
              <div class="flex flex-grow"/>
              <button type="button"
                      class="color_enabled p-2 items-center rounded-md text-sm font-medium shadow-sm"
                      @:click="$emit('editEdge', edge.edgeId)">
                <PencilIcon class="-ml-1 -mr-1 h-6 w-6 py-0" aria-hidden="true"/>
              </button>
            </div>
            <div class="mx-8">
              <button class="my-4 border w-full rounded-md py-1 button2"
                      @click="editorState.capturingEndNode=true">
                {{ getNewEdgeStr() }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <div class="flex-col w-full ">
            <p class=" color_disabled -ml-2 text-sm uppercase py-1">
              {{ $t("str_node_stations_title") }}:
            </p>

            <div class="flex flex-row">
              <input type="checkbox" class="m-2 flex button" v-model="addNodeForm.charging_station"/>
              <p class=" place-self-center color_enabled cursor-default"
                 @click="addNodeForm.charging_station=!addNodeForm.charging_station">
                {{ $t("str_is_node_charging") }}</p>
            </div>

            <div class="m-2 flex flex-row" v-if="addNodeForm.charging_station">
              <p class="flex color_enabled">{{ $t("str_station_priority") }}:</p>
              <Dropdown class=" place-self-center cursor-default px-4 "
                        :elem-list="stationPriorities"
                        :prompt="stationPriorities[addNodeForm.charging_priority]"
                        :default-selection-id="stationPriorities[addNodeForm.charging_priority]"
                        @selectedEl="setChargingPriority"/>
            </div>

            <div class="flex flex-row">
              <input type="checkbox" class="m-2 flex button" v-model="addNodeForm.idle_station"/>
              <p class=" place-self-center color_enabled cursor-default"
                 @click="addNodeForm.idle_station=!addNodeForm.idle_station">
                {{ $t("str_is_node_idle") }}</p>
            </div>

            <div class="m-2 flex flex-row" v-if="addNodeForm.idle_station">
              <p class="flex color_enabled ">{{ $t("str_station_priority") }}:</p>
              <Dropdown class=" place-self-center cursor-default px-4 "
                        :elem-list="stationPriorities"
                        :prompt="stationPriorities[addNodeForm.idle_priority]"
                        :default-selection-id="stationPriorities[addNodeForm.idle_priority]"
                        @selectedEl="setIdlePriority"/>
            </div>


            <div class="flex flex-row">
              <input type="checkbox" class="m-2 flex button" v-model="addNodeForm.fire_alarm_station"/>
              <p class=" place-self-center color_enabled cursor-default"
                 @click="addNodeForm.fire_alarm_station=!addNodeForm.fire_alarm_station">
                {{ $t("str_is_node_safe_zone_fire") }}</p>
            </div>

          </div>
        </li>
      </ul>
      <br>
      <!--      Actions  -->
      <ActionsView :scope="0" :savedActions="actionArray" @resultActions="retrieveActions"/>
      <ul class="mx-8 my-2">
        <li class="flex flex-row flex-grow mb-2">
          <button @click="saveNode" class="border w-full rounded-md py-1 button2">
            {{ $t("str_save_node") }}
          </button>
        </li>
        <li class="flex flex-row flex-grow mb-2">
          <button type="button" class="border w-full rounded-md py-1 deletebutton"
                  @click="deleteNode"
                  v-show="nodeId!==-1 || nodeId!==undefined">
            <div class="inline-flex">
              <TrashIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"/>
              <p class="ml-2">{{ $t("str_delete_node") }}</p>
            </div>
          </button>
        </li>
      </ul>
    </main>
  </div>
</template>

<script setup lang="ts">
import {ChevronLeftIcon, ChevronRightIcon, PlusIcon, XMarkIcon} from '@heroicons/vue/20/solid'
import {PencilIcon, TrashIcon} from '@heroicons/vue/24/outline'
import ActionsView from "@/components/ActionsView.vue";
import Dropdown from "@/components/Dropdown.vue";
import VehicleDropdown from "@/components/VehicleDropdown.vue";</script>

<script lang="ts">
import {defineComponent} from 'vue'
import * as ApiManager from "../../network/ApiManager";
import * as AlertManager from '@/datamanagers/AlertsManager'
import {prod_safe_log} from '@/utils'
import {liveViewEditorState} from "@/dtos/AppState";
import {AgvPosition, vehicleStates} from '@/dtos/VehicleState'
import {publishControlCenterMessage} from '@/network/MqttManager'
import {useConfig} from "@/main";
import { EdgesManager } from "@/datamanagers/EdgesManager";
import { NodesManager } from "@/datamanagers/NodesManager";
import { TrajectoryData } from '@/helpers/TrajectoryHelper';
import { LiveViewHelper } from '@/helpers/LiveViewHelper';
import ArrowControls from '../ArrowControls.vue';
export default defineComponent({
  name: "NewNodeModal",
  data() {
    return {
      editorState: liveViewEditorState,
      nodeId: -1,
      addNodeForm: {
        nodeDescription: "",
        nodePosition: {
          x: "",
          y: "",
          theta: "",
          allowedDeviationXY: "",
          allowedDeviationTheta: "",
          mapId: "",
          mapDescription: ""
        },
        charging_station: false,
        charging_priority: 1,
        idle_station: false,
        idle_priority: 1,
        fire_alarm_station: false, 
        "actions": new Array()
      },
      actionArray: [] as any[],
      nodeIndexInStack: 0,
      selected_vehicle: '',
      vehicleStates,
      stationPriorities: [this.$t("str_priority_high"), this.$t("str_priority_normal"), this.$t("str_priority_low")],
      initialNodePosition: {} as any,
      recalculateEdgesTimeout: null as ReturnType<typeof setTimeout> | null,
      savedConnectedEdges: [] as any[]
    }
  },
  props: {
    addNode: {
      type: Boolean,
      default: false
    },
    savedNode: {
      type: null,
      default: null
    },
    nodeStack: {
      type: null,
      default: null
    },
    selectedMap: {
      type: String,
      default: ""
    },
    connectedEdges: {
      type: Array,
      default: []
    }
  },
  watch: {
    editorState: {
      handler: function (newVal) {
        if (!newVal.editMode) {
          this.closeModal();
        }
      },
      deep: true
    },
    selectedMap(newValue, oldValue) {
      if (this.savedNode == null) {
        this.addNodeForm.nodePosition.mapId = newValue
      }
    },
    savedNode(newValue, oldValue) {
      if (Object.keys(this.initialNodePosition).length){
        // If there was other node selected - use initial position to re-set nodes trajectories)
        this.recalculateEdges(this.initialNodePosition, this.savedConnectedEdges, true);
      }
      this.importSavedNode(newValue)
    },
    "addNodeForm.nodePosition.x": function(newVal) {
      if (!isNaN(newVal) && newVal.length > 0) {
        this.recalculateEdges();
      }
    },
    "addNodeForm.nodePosition.y": function(newVal) {
      if (!isNaN(newVal) && newVal.length > 0) {
        this.recalculateEdges();
      }
    }
  },
  mounted() {
    this.importSavedNode(this.savedNode);
  },
  methods: {
    closeModal() {
      this.recalculateEdges(this.initialNodePosition, this.savedConnectedEdges, true);
      setTimeout(() => {this.$emit('closeModal')}, 100);
    },
    getNewEdgeStr() {
      if (liveViewEditorState.capturingEndNode) {
        // @ts-ignore
        return this.$t("str_click_on_end_node")
        // @ts-ignore
      } else return "".concat("+ ", this.$t("str_add_edge"))
    },
    recalculateEdges(initialPosition?: any, connectedEdgesArray?: any[], isInstant?: boolean) {
      if (this.recalculateEdgesTimeout) {
        clearTimeout(this.recalculateEdgesTimeout);
      }
      const editedNode = this.nodeId;

      let coordinates: any;
      if (!!initialPosition) {
        coordinates = structuredClone(initialPosition);
      } else {
        coordinates = structuredClone(this.addNodeForm.nodePosition);
      }

      let edgesArray: any;
      if (connectedEdgesArray) {
        edgesArray = structuredClone(connectedEdgesArray);
      } else {
        edgesArray = structuredClone(this.connectedEdges);
      }

      this.recalculateEdgesTimeout = setTimeout(() => {
        if (!edgesArray || !Array.isArray(edgesArray) || !edgesArray?.length) return;
        edgesArray.forEach((edge: any) => {
          const processedEdge = EdgesManager.edges.get(edge.edgeId);
          let pointIndex = -1;
          if (processedEdge.startNodeId === editedNode) {
            pointIndex = 0;
          } else if (processedEdge.endNodeId === editedNode) {
            pointIndex = -1;
          }
          const trajectory = EdgesManager.trajectories.get(processedEdge.edgeId);
          processedEdge.trajectory.inputPoints.at(pointIndex).x = trajectory.inputPoints.at(pointIndex).x = parseFloat(coordinates.x);
          processedEdge.trajectory.inputPoints.at(pointIndex).y = trajectory.inputPoints.at(pointIndex).y = parseFloat(coordinates.y);
          LiveViewHelper.FitTrajectory(trajectory, processedEdge)
            .then(() => {
              this.$emit('invalidateTrajectory', processedEdge.edgeId);
            });
        });
      }, isInstant ? 0 : 200);
    },
    importSavedNode(newNode: any) {
      if (newNode == null) return;
      let importedNode: any;
      if (typeof newNode === 'number'){
        importedNode = NodesManager.nodes.get(newNode)
      } else {
        importedNode = newNode;
      }
      if (importedNode?.nodeId != undefined && importedNode?.nodeId !== -1) this.nodeId = importedNode.nodeId
      else this.nodeId = -1

      this.savedConnectedEdges = structuredClone(this.connectedEdges);
      this.addNodeForm.nodeDescription = importedNode?.nodeDescription ?? ""
      this.addNodeForm.nodePosition = importedNode.nodePosition
      this.addNodeForm.nodePosition.theta = importedNode.nodePosition?.theta ?? ""
      this.addNodeForm.nodePosition.allowedDeviationXY = importedNode.nodePosition?.allowedDeviationXY ?? ""
      this.addNodeForm.nodePosition.allowedDeviationTheta = importedNode.nodePosition?.allowedDeviationTheta ?? ""
      this.addNodeForm.nodePosition.mapId = importedNode.nodePosition?.mapId ?? this.selectedMap
      this.addNodeForm.nodePosition.mapDescription = importedNode.nodePosition?.mapDescription ?? ""
      this.initialNodePosition = structuredClone(this.addNodeForm.nodePosition);
      this.addNodeForm.charging_station = false;
      setTimeout(() => this.addNodeForm.charging_station = importedNode?.charging_station ?? false)
      this.addNodeForm.charging_priority = importedNode?.charging_priority ?? 1
      this.addNodeForm.idle_station = false;
      setTimeout(() => this.addNodeForm.idle_station = importedNode?.idle_station ?? false)
      this.addNodeForm.idle_priority = importedNode?.idle_priority ?? 1
      this.addNodeForm.fire_alarm_station = importedNode?.fire_alarm_station ?? false
      this.actionArray = [];
      if (importedNode?.actions?.length > 0)
        this.actionArray = this.savedNode.actions.map((obj: any) => {
          const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
              map.set(obj.key, obj), new Map()).values()];
          obj.action.predefined_action = true
          return {action: {...obj.action}, actionParameters: mergedParamArray, blockingType: obj.blockingType, actionSequenceId: obj.actionSequenceId}
        })
    },
    retrieveActions(newActions: any[]) {
      this.addNodeForm.actions = newActions
    },
    saveNode() {
      const payload = this.addNodeForm;
      // replace comma with a dot to correctly parce decimals.
      payload.nodePosition.x = payload.nodePosition.x.toString().replace(',','.');
      payload.nodePosition.y = payload.nodePosition.y.toString().replace(',','.');
      if (this.validateNode(payload)) {
          for (var action of payload.actions) {
            action.actionParameters = action.actionParameters.filter((parameter: any) => parameter.key !== '' &&
                parameter.value !== '');
          }
          if (this.nodeId === -1 || this.nodeId == undefined) {
            ApiManager.addNode(payload, () => prod_safe_log('Could not add node.'),
                () => {
                  console.log('Node successfully added.');
                  this.initialNodePosition = this.addNodeForm.nodePosition;
                  this.closeModal();
                });
          } else {
            ApiManager.editNode(this.nodeId, payload, () => prod_safe_log('Could not add node.'),
                () => {
                  console.log('Node successfully edited.');
                  this.initialNodePosition = this.addNodeForm.nodePosition;
                  this.closeModal();
                });
            // When editing node there can be connected edges and if coordinates changed - need to save changed edge.
            if (this.connectedEdges && this.connectedEdges?.length) {
              this.connectedEdges.forEach((edge: any) => {
                const trajectory = EdgesManager.trajectories.get(edge.edgeId);
                const apiTrajectory = {...trajectory.nurbsCurveVda, inputPoints: edge.trajectory.inputPoints}
                const edgeData = {...edge, length: edge.length, trajectory: apiTrajectory};
                let retryFlag = false;
                ApiManager.editEdge(
                  edge.edgeId,
                  edgeData,
                (err: any) => {
                  prod_safe_log('Could not edit edge.');
                  if (err.response.status === 500 && !retryFlag){
                    retryFlag = true;
                    ApiManager.editEdge(edge.edgeId, edgeData, () => prod_safe_log('Could not edit edge.'),
                      () => {
                        console.log('Edge successfully edited.');
                      });
                  }
                },
                () => {
                  console.log('Edge successfully edited.');
                });
              });

            }
          }
      }
    },
    validateNode(node: any) {
      if (node.nodePosition.x === "" || node.nodePosition.y === "") {
        // @ts-ignore
        AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_adding_new_node"), this.$t("str_fill_form_correctly")))
        return false;
      }
      if (node.nodePosition.allowedDeviationXY === "") {
        node.nodePosition.allowedDeviationXY = null
      }
      if (node.nodePosition.allowedDeviationTheta === "") {
        node.nodePosition.allowedDeviationTheta = null
      }
      if (node.nodePosition.theta === "") {
        node.nodePosition.theta = null
      }
      return true
    },
    deleteNode() {
      ApiManager.deleteNode(this.nodeId, () => prod_safe_log('Could not add node.'),
          () => {
            this.closeModal()
            console.log('Node successfully deleted.')
          });
    },
    loadNextNode() {
      if (this.nodeStack.length < 2) return; // safety check to prevent runtime errors when there is only 1 item in the list
      this.nodeIndexInStack = Math.min(this.nodeIndexInStack + 1, this.nodeStack.length - 1);
      this.$emit("newStackIdLoaded", this.nodeIndexInStack);
    },
    loadPrevNode() {
      if (this.nodeStack.length < 2) return; // safety check to prevent runtime errors when there is only 1 item in the list
      this.nodeIndexInStack = Math.max(this.nodeIndexInStack - 1, 0);
      this.$emit("newStackIdLoaded", this.nodeIndexInStack);
    },
    createNodeOnPosition() {
      this.nodeId = -1
      const nodePosition = this.addNodeForm.nodePosition
      this.addNodeForm = {
        nodeDescription: "",
        nodePosition: nodePosition,
        "actions": new Array(),
        charging_station: false,
        idle_station: false,
        fire_alarm_station: false,
        charging_priority: 1,
        idle_priority: 1,
      }
    },
    read_vehicle_position() {
      const pos: AgvPosition | undefined = this.vehicleStates.get(this.selected_vehicle)?.agvPosition
      this.addNodeForm.nodePosition.x = pos?.x
      this.addNodeForm.nodePosition.y = pos?.y
      this.addNodeForm.nodePosition.theta = pos?.theta
      this.recalculateEdges();
    },
    setIdlePriority(prio: any) {
      this.addNodeForm.idle_priority = this.stationPriorities.indexOf(prio)
    },
    setChargingPriority(prio: any) {
      this.addNodeForm.charging_priority = this.stationPriorities.indexOf(prio)
    },
    setVehicleId(selectedId: string) {
      this.selected_vehicle = selectedId
    },
    unblockNode() {
      const mqttConfig = useConfig().config.configuration.mqtt
      const topic = `${mqttConfig.emmInterfaceName}/${mqttConfig.emmVersion}/${mqttConfig.manufacturer}/${mqttConfig.ccSubtopic}/unblockGraph`
      const message = {nodeId: this.nodeId}
      publishControlCenterMessage(mqttConfig,
          window.location.hostname,
          window.location.protocol,
          topic,
          message
      )
    },
    processArrowClick(payload: any) {
      this.addNodeForm.nodePosition.x = String(Number(this.addNodeForm.nodePosition.x) + Number(payload.x));
      this.addNodeForm.nodePosition.y = String(Number(this.addNodeForm.nodePosition.y) + Number(payload.y));
    }
  }
})

</script>

<style>
</style>
