<template>
  <div :class="{'w-56':sidebarOpen,
  'z-40 w-16 xl:w-56 fixed inset-y-0 flex flex-col navigation_transition':true}">
    <div class="flex flex-grow flex-col overflow-y-auto overflow-x-clip color_background_dark pt-5">
      <div class="hidden xl:flex flex-shrink-0 items-center px-4" @click="() => {hiddenAdministrationRoute()}">
        <img class="h-8 w-auto" src="../assets/logo.png"
             alt="Emm! Solutions"/>
        <p class="color_disabled mx-4 text-clip whitespace-nowrap break-keep">{{ $t('str_control_center') }}</p>
      </div>
      <button type="button"
              class="xl:hidden px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              @click="sidebarOpen = !sidebarOpen">
        <span class="sr-only">Open sidebar</span>
        <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true"/>
      </button>
      <div class="mt-5 flex flex-1 flex-col overflow-x-hidden overflow-y-auto">
        <nav class="flex-1 space-y-1 pb-4">
          <router-link to="/" :class="{'menu_item_selected': currentRoute === navigation[0].link,
                      'menu_item_deselected': currentRoute !== navigation[0].link,
                 'group flex items-center px-4 py-2 text-sm font-medium': true}"
                       @click="sidebarOpen=false">
            <MapIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ $t(navigation[0].name) }}</p>
            <div class="flex flex-grow"/>
            <button type="button"
                    v-if="currentUserState?.username.length"
                    v-bind:class="[editorState.editMode ? 'color_enabled' : 'color_disabled', 'relative px-2 inline-flex items-center rounded-md text-sm font-medium shadow-sm']"
                    v-on:click="editorState.editMode = !editorState.editMode">
              <PencilIcon class="-ml-1 -mr-1 h-6 w-6 " aria-hidden="true"/>
            </button>
          </router-link>
          <router-link v-if="currentUserState?.username.length" to="/orders" :class="{'menu_item_selected': currentRoute === navigation[1].link,
          'menu_item_deselected': currentRoute !== navigation[1].link,
                 'group flex items-center px-4 py-2 text-sm font-medium': true}"
                       @click="sidebarOpen=false">
            <ClipboardDocumentCheckIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ $t(navigation[1].name) }}</p>
          </router-link>
          <router-link v-if="currentUserState?.username.length" to="/quick_routes" :class="{'menu_item_selected': currentRoute ===
              navigation[2].link,
              'menu_item_deselected': currentRoute !== navigation[2].link,
                 'group flex items-center px-4 py-2 text-sm font-medium': true}"
                       @click="sidebarOpen=false">
            <BookmarkIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ $t(navigation[2].name) }}</p>
          </router-link>
          <router-link v-if="currentUserState?.username.length && useConfig().config.configuration?.custom_features?.mqtt?.knauf!=null" to="/knauf_scan"
                       :class="{'menu_item_selected': currentRoute === '/knauf_scan',
              'menu_item_deselected': currentRoute !== '/knauf_scan',
                 'group flex items-center px-4 py-2 text-sm font-medium': true}"
                       @click="sidebarOpen=false">
            <QrCodeIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ $t("str_barcode_menu_title") }}</p>
          </router-link>
          <router-link v-if="currentUserState?.username.length" to="/user_management"
                       :class="{'menu_item_selected': currentRoute === '/user_management',
              'menu_item_deselected': currentRoute !== '/user_management',
                 'group flex items-center px-4 py-2 text-sm font-medium': true}"
                       @click="sidebarOpen=false">
            <UsersIcon v-if="currentUserState?.role.role_level === 0" class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <UserIcon v-if="currentUserState?.role.role_level !== 0" class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ currentUserState?.role.role_level === 0 ? $t('str_usermanagement_user_management') : $t('str_usermanagement_account_settings') }}</p>
          </router-link>
          <a v-if="!currentUserState?.username?.length" v-on:click="showSignInModal" class="cursor-pointer menu_item_deselected group flex items-center px-4 py-2 text-sm font-medium">
            <ArrowLeftOnRectangleIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
            <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ $t('str_usermanagement_signin') }}</p>
          </a>
          <div v-if="currentUserState?.username.length" :class="[currentRoute.includes(navigation[4].link) ? 'color_background_light':'color_background_dark']">
            <div v-if="defaultVehicle.length && useConfig().localConfig.hard_vehicle_restriction">
              <router-link :to="{path: `${navigation[4].link}/${defaultVehicle}`, params: {id: defaultVehicle}}"
                            :class="[getSubmenuClass(defaultVehicle),
                            'group flex items-center px-4 py-2 text-sm font-medium cursor-pointer']"
                            @click="sidebarOpen=false">
                  <div class="flex items-center flex-nowrap whitespace-nowrap max-w-full">
                    <TruckIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
                    <div v-if="vehicleStates.get(defaultVehicle)?.safetyState?.eStop === 'NONE'" :class="[!vehicleStates.get(defaultVehicle)?.connected ?
                          'text-transparent' : getVehicleCheckColor(vehicleStates.get(defaultVehicle)),
                          'h-2 w-2 rounded-full mx-2 ml-0 min-w-2']" style="min-width:0.5rem"/>
                    <StopCircleIcon v-if="vehicleStates.get(defaultVehicle)?.safetyState?.eStop !== 'NONE'" class="h-4 w-4 min-w-[1rem] -ml-1 mr-1 px-0 text-red-500"/>
                      {{ vehicleStates.get(defaultVehicle)?.name }}-{{ defaultVehicle }} {{ $t('str_overview') }}
                  </div>
                  <ExclamationTriangleIcon v-if="typeof vehicleStates.get(defaultVehicle)?.agvPosition?.positionInitialized === 'boolean' && !vehicleStates.get(defaultVehicle)?.agvPosition?.positionInitialized" class="w-5 h-5 text-orange-500 ml-1"/>
              </router-link>
            </div>
            <div v-if="!useConfig().localConfig.hard_vehicle_restriction" :class="[currentRoute.includes(navigation[4].link)? 'menu_item_selected':'menu_item_deselected',
              'group flex items-center px-4 py-2 text-sm font-medium cursor-pointer']"
                 @click="vehiclesExpanded=!vehiclesExpanded; sidebarOpen=true">
              <TruckIcon class="w-6 h-6 mr-2" style="min-width: 24px"/>
              <p class="ml-4 text-clip whitespace-nowrap break-keep">{{ $t(navigation[4].name) }}</p>
              <div class="flex flex-grow"/>
              <ChevronRightIcon v-show="!vehiclesExpanded" class="h-4 w-4" aria-hidden="true"/>
              <ChevronDownIcon v-show="vehiclesExpanded" class="h-4 w-4" aria-hidden="true"/>
            </div>
            <div :class="[vehiclesExpanded ? 'vehicles_expanded':'', 'vehicles_wrapper']">
              <div class="overflow-hidden">
                <div v-if="defaultVehicle.length && !useConfig().localConfig.hard_vehicle_restriction" :key="String(defaultVehicle)">
                  <router-link :to="{path: `${navigation[4].link}/${defaultVehicle}`, params: {id: defaultVehicle}}"
                               :class="[getSubmenuClass(defaultVehicle),
                               'group flex items-center px-8 py-2 text-sm font-medium justify-between']"
                               @click="sidebarOpen=false">
                      <div class="flex items-center">
                        <div v-if="vehicleStates.get(defaultVehicle)?.safetyState?.eStop === 'NONE'" :class="[!vehicleStates.get(defaultVehicle)?.connected ?
                              'text-transparent' : getVehicleCheckColor(vehicleStates.get(defaultVehicle)),
                              'h-2 w-2 rounded-full mx-4 ml-8']"/>
                          <StopCircleIcon v-if="vehicleStates.get(defaultVehicle)?.safetyState?.eStop !== 'NONE'" class="h-4 w-4 min-w-[1rem] mx-3 ml-7 px-0 text-red-500"/>
                          {{ vehicleStates.get(defaultVehicle)?.name }}-{{ defaultVehicle }}
                      </div>
                      <ExclamationTriangleIcon v-if="typeof vehicleStates.get(defaultVehicle)?.agvPosition?.positionInitialized === 'boolean' && !vehicleStates.get(defaultVehicle)?.agvPosition?.positionInitialized" class="w-5 h-5 text-orange-500 ml-1"/>
                  </router-link>
                </div>
                <div v-if="!useConfig().localConfig.hard_vehicle_restriction">
                  <div v-for="vehicleId in Array.from(vehicleStates?.keys()).sort()" :key="vehicleId">
                    <router-link v-if="!defaultVehicle.length || defaultVehicle !== vehicleId" :to="{path: `${navigation[4].link}/${vehicleId}`, params: {id: vehicleId}}"
                                :class="[getSubmenuClass(vehicleId),
                                'group flex items-center px-8 py-2 text-sm font-medium justify-between']"
                                @click="sidebarOpen=false">
                        <div class="flex items-center">
                          <div v-if="vehicleStates.get(vehicleId)?.safetyState?.eStop === 'NONE'" :class="[!vehicleStates.get(vehicleId)?.connected ?
                                'text-transparent' : getVehicleCheckColor(vehicleStates.get(vehicleId)),
                                'h-2 w-2 rounded-full mx-4 ml-8']"/>
                            <StopCircleIcon v-if="vehicleStates.get(vehicleId)?.safetyState?.eStop !== 'NONE'" class="h-4 w-4 min-w-[1rem] mx-3 ml-7 px-0 text-red-500"/>
                            {{ vehicleStates.get(vehicleId)?.name }}-{{ vehicleId }}
                        </div>
                        <ExclamationTriangleIcon v-if="typeof vehicleStates.get(vehicleId)?.agvPosition?.positionInitialized === 'boolean' && !vehicleStates.get(vehicleId)?.agvPosition?.positionInitialized" class="w-5 h-5 text-orange-500 ml-1"/>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="flex flex-shrink-0 items-center px-4 flex-col">
        <div class="my-8 w-full" v-if="currentUserState?.username.length && (!editorState.editMode && currentRoute === navigation[0].link)">
          <button class="button border rounded-lg w-full p-1"
                  @click="editorState.newOrderOpen=true; sidebarOpen = false">
            <p class="hidden xl:block xl:h-full">{{ $t("str_new_order") }} </p>
            <p class="block xl:hidden text-clip whitespace-nowrap break-keep">{{
                sidebarOpen ? $t("str_new_order") : '+'
              }}</p>
          </button>

        </div>
        <div class="flex flex-row w-full relative sm:pb-4 xl:pb-0" style="justify-content: center; align-items: center">
          <!-- Status Indicators -->
          <div class="bottom-4 xl:bottom-5 left-0 absolute flex flex-row">
            <div class="tooltip-wrapper mr-1">
              <div :class="{'online': connectionStates.is_mqtt_active, 'status-indicator': true}"></div>
              <div class="tooltip">
                MQTT status: {{ connectionStates.is_mqtt_active ? 'online' : 'offline' }}
              </div>
            </div>
            <div class="tooltip-wrapper mr-1">
              <div :class="{'online': connectionStates.is_api_sse_active, 'status-indicator': true}"></div>
              <div class="tooltip">
                SSE status: {{ connectionStates.is_api_sse_active ? 'online' : 'offline' }}
              </div>
            </div>
          </div>
          <!-- /Status Indicators -->
          <button @click="setLocaleUI('en'); editorState.locale = 'en'"
                  v-bind:class="[i18n.getLocale() === 'en' ? 'color_enabled' : 'color_disabled', 'rounded-md py-4 px-2 flex items-center text-sm uppercase']">
            en
          </button>
          <p class="color_disabled flex items-center">|</p>
          <button @click="setLocaleUI('de'); editorState.locale = 'de'"
                  v-bind:class="[i18n.getLocale() === 'de' ? 'color_enabled' : 'color_disabled', 'rounded-md py-4 px-2 flex items-center text-sm uppercase']">
            de
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import {
  Bars3BottomLeftIcon,
  BookmarkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  MapIcon,
  PencilIcon,
  QrCodeIcon,
  TruckIcon,
  UsersIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon, StopCircleIcon } from '@heroicons/vue/24/solid';
import {useI18n} from "vue3-i18n";
import {getLocale, setLocale} from "@/datamanagers/StorageManager";
import {getVehicleCheckColor} from "@/utils";
import {connectionStates} from "@/datamanagers/StateIndicatorsManager";

const i18n = useI18n();
const setLocaleUI = (lang: string) => {
  i18n.setLocale(lang);
  setLocale(lang)
}
const lang = getLocale()
try {
  setLocaleUI(lang ?? useConfig().config.configuration.default_locale)
} catch (e) {
  console.warn("Caught error setting locale")
  console.warn(e)
}
const {t} = useI18n();

const navigation = [
  {name: 'str_live_view', link: '/'},
  {name: 'str_jobs_view', link: '/orders'},
  {name: 'str_menu_order_templates', link: '/quick_routes'},
  {name: 'str_barcode_menu_title', link: '/knauf_scan'},
  {name: 'str_vehicles_view', link: '/agv_details'},
]
</script>

<script lang="ts">
import {defineComponent} from "vue";
import {liveViewEditorState} from "@/dtos/AppState";
import {vehicleStates} from "@/dtos/VehicleState";
import {useConfig} from "@/main";
import {currentUserState} from "@/datamanagers/ReactiveStates";
// TODO: load the screen breakpoint from tailwind to match xl width
const XL_WIDTH = 1200
export default defineComponent({
  name: "SideNavigation",
  data() {
    return {
      editorState: liveViewEditorState,
      vehicleStates,
      vehiclesExpanded: false,
      sidebarOpen: false,
      compactedView: window.innerWidth < XL_WIDTH,
      hiddenActivationClickCounter: 0,
      hiddenActivationTimeout: null as any,
      currentUserState,
      defaultVehicle: useConfig().localConfig.default_vehicle ? String(useConfig().localConfig.default_vehicle) : '',
    }
  },
  props: {
    parentSideBarOpen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    parentSideBarOpen(value, oldValue) {
      this.sidebarOpen = value
    },
    sidebarOpen(value, oldValue) {
      this.$emit('navigationExpanded', value)
      if (!value && window.innerWidth < XL_WIDTH) this.vehiclesExpanded = false
    },
    compactedView(value, newValue) {
      // side nav shrank
      if (value && !newValue) {
        if (!this.sidebarOpen) this.vehiclesExpanded = false
      }
    },
  },
  computed: {
    currentRoute() {
      return this.$router.currentRoute.value.path
    },
  },
  methods: {
    getSubmenuClass(vehicleId: string) {
      if (this.currentRoute === `/agv_details/${vehicleId}`) {
        return 'submenu_item_selected'
      } else if (this.currentRoute.includes('/agv_details')) {
        return 'submenu_item_deselected'
      } else return 'menu_item_deselected'
    },
    updateScreenWidth() {
      // Update the screen width when the resize event occurs
      this.compactedView = window.innerWidth < XL_WIDTH;
    },
    showSignInModal() {
      this.$emit('showSignInModal');
    },
    hiddenAdministrationRoute() {
      if (this.currentUserState?.role.role_level !== 0) return;
      this.hiddenActivationClickCounter += 1;

      if (this.hiddenActivationTimeout) {
        clearTimeout(this.hiddenActivationTimeout);
      }

      if (this.hiddenActivationClickCounter >= 15){
        this.$router.push({ path: '/administration_control_panel' });
      }

      this.hiddenActivationTimeout = setTimeout(() => {
        this.hiddenActivationClickCounter = 0;
      }, 500);
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
})
</script>

<style scoped>

.vehicles_wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;
}

.vehicles_wrapper.vehicles_expanded {
  grid-template-rows: 1fr;
}

.navigation_transition {
  transition: width 0.3s ease-in-out;
}

.menu_item_deselected {
  background-color: #1F2936;
  color: #819595;
}

.menu_item_deselected:hover {
  background-color: #1F2936;
  color: #F8F2DC;
}

.menu_item_selected {
  background-color: #313D4C;
  color: #F8F2DC;
}

.submenu_item_deselected {
  background-color: #313D4C;
  color: #a6bebe;
}

.submenu_item_deselected:hover {
  background-color: #313D4C;
  color: #F8F2DC;
}

.submenu_item_selected {
  background-color: #313D4C;
  color: #F8F2DC;
}
.tooltip-wrapper:hover .tooltip{
  display:block;
}

.tooltip{
  white-space: nowrap;
  position: absolute;
  bottom:50%;
  left:50%;
  background:lightgray;
  font-size:10px;
  padding:2px;
  display:none;
}

.status-indicator{
  width:10px;
  height:10px;
  border-radius:50%;
  background: gray;
}

.status-indicator.online{
  background:green;
}
</style>
