/**
 * The AlertsManager is responsible for handling logic for storing notification list
 * and adding/removing notifications frm the queue. 
 * Available from any point of app, shows notifications immidiately. 
 */

import {reactive} from 'vue'

interface Alert {
    id: number;
    showAlertVar: boolean;
    alertTitle: string;
    alertText: string;
    alertType: number;
    alertStacked: number;
}

interface Alerts {
    alert_id: number;
    alerts: Alert[];
}
// alerts queue
export const alerts: Alerts = reactive({
    alert_id: 0,
    alerts: [],
});

// Notification structure
interface Notification {
  title:string, 
  text: string,
  type: number
}

// Notification type structure
const NotificationType = {
    error:0,
    warning:1,
    success:2
  }

// Adds notification to the queue, after what it is immediately rendered in App.vue
function showAlert(msg:Notification) {
    alerts.alert_id++;
    let stacked = 1;
    if (alerts.alerts.length !== 0 && msg.text == alerts.alerts[alerts.alerts.length - 1].alertText && msg.title == alerts.alerts[alerts.alerts.length - 1].alertTitle ){
      alerts.alerts[alerts.alerts.length - 1].alertStacked++;
    } else {
      alerts.alerts.push({ id: alerts.alert_id, showAlertVar: true, alertTitle: msg.title, alertText: msg.text, alertType: msg.type, alertStacked: stacked })
    }
  }

// Removes notification from queue 
function closeAlert(id: number) {
    const element = alerts.alerts.find(el => (el.id === id))
    if (element) {
      const idx = alerts.alerts.indexOf(element)
      alerts.alerts.splice(idx, 1)
    }
  }

// Creates error notification without exposing notification structure 
function createErrorAlert(title:string, text:string){
    const msg:Notification = {title:title, text:text, type: NotificationType.error}
    return msg
}

// Creates warning notification without exposing notification structure 
function createWarningAlert(title:string, text:string){
    const msg:Notification = {title:title, text:text, type: NotificationType.warning}
    return msg
}

// Creates success notification without exposing notification structure 
function createSuccessAlert(title:string, text:string){
    const msg:Notification = {title:title, text:text, type: NotificationType.success}
    return msg
}

export {showAlert, closeAlert, Notification, NotificationType, createErrorAlert, createWarningAlert, createSuccessAlert}
