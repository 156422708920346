/**
 * Reactive states store real-time data about the UI connections statuses and automatically re-render subscribed indicators on change
 */

import { reactive } from 'vue'

// state of the job filters
export const connectionStates = reactive({
  is_mqtt_active: false,
  is_license_activate: false,
  is_api_initialized: false,
  is_api_sse_active: false,
  is_power_saving_mode_activated: false
});