<template>
      <div class="h-screen mx-auto px-6 grid lg:max-w-10xl grid-cols-12 gap-8  ">
        <div :class="{'col-span-7 lg:col-span-9' :  currentUserState?.username?.length, 'col-span-12': !currentUserState?.username?.length}">
          <nav aria-label="MainMap" class="h-screen sticky top-6 divide-y divide-gray-300 ">
            <div class="flex relative z-10" style="align-items: center; height:50px;">
              <p class="mr-3 color_enabled flex-grow-0">{{ $t("str_select_map") }}:</p>
                  <Listbox as="div" v-model="current_map" class="relative flex-grow">
                      <ListboxButton style="height:30px; "
                                     class="relative l-0 r-0 w-full cursor-default rounded-sm border border-gray-300 bg-white pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                        <span class="block truncate">{{ current_map.map_img }}</span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </span>
                      </ListboxButton>

                      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                                  leave-to-class="opacity-0">
                        <ListboxOptions
                            class="absolute inset-x-0 mt-1 max-h-60 overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <ListboxOption as="template" v-for="map in map_configs" :key="map"
                                         :value="map" v-slot="{ active, selected }" @click="switchMap()">
                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                    map.map_img
                                  }}</span>

                              <span v-if="selected"
                                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                                        </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                  </Listbox>
            </div>
              <div style="border:0;" class="mb-1">
                <label class="color_enabled border-0 mr-10"><input type="checkbox" @checked="showVehicleLabels" v-model="showVehicleLabels"/> {{ $t("str_show_vehicle_labels") }}</label>
                <label class="color_enabled border-0 mr-10"><input type="checkbox" @checked="showNodeLabels" v-model="showNodeLabels"/> {{ $t("str_show_node_labels") }}</label>
                <label class="color_enabled border-0"><input type="checkbox" @checked="markOccupiedNodesEdges" v-model="markOccupiedNodesEdges"/> {{ $t("str_mark_occupied_nodes_edges") }}</label>
              </div>
              <div
                  style="overflow: hidden; border:1px solid #000; height: calc(100% - 80px);">
                  <canvas id="myCanvas" :height="liveViewHelper.canvas_height*zoom_scale"
                          :width="liveViewHelper.canvas_width*zoom_scale"></canvas>
              </div>
          </nav>
        </div>
        <main v-if="currentUserState?.username?.length" class="col-span-5 lg:col-span-3 color_background_dark"
              style="overflow: hidden;overflow-x: auto; overflow-y: auto; height: 100%;">
          <new-node-modal v-if="showAddNode&&editorState.editMode"
                          @closeModal="() => closeEditNodeModal()"
                          @invalidateTrajectory="(edgeId: number) => cachedEdgesTrajectories.delete(edgeId)"
                          @newStackIdLoaded="selectNodeFromStack"
                          @editEdge="selectEdge"
                          :addNode="showAddNode&&editorState.editMode"
                          :savedNode="selectedNode" :nodeStack="selectedNodes" :selectedMap="current_map.map_id"
                          :connected-edges="liveViewHelper.getConnectedEdges(visibleEdges, selectedNode?.nodeId) ?? []"/>
          <new-edge-modal v-if="showAddEdge&&editorState.editMode"

                          @closeModal="() => closeEditEdgeModal()"
                          @newStackIdLoaded="selectEdgeFromStack"
                          :addEdge="showAddEdge"
                          :savedEdge="selectedEdge" :edgeStack="selectedEdges" :selectedComponents="selectedComponents" 
                          @updateConnectedComponent="updateConnectedComponent"
                          @createConnectedComponent="createConnectedComponent"
                          @deleteConnectedComponent="deleteConnectedComponent"
                          />
          <ul v-show="!editorState.editMode && !editorState.newOrderOpen"
              role="list" class="relative z-0 divide-y divide-orange-100">
              <li v-if="useConfig().config.configuration.power_saving_available">
                <div class="flex flex-row m-2 py-3 flex-nowrap items-center justify-between">
                  <p class="ml-4 truncate color_enabled">
                    {{ $t('str_power_saving_mode')}}:
                  </p>
                  <Switch
                      @click="confirmEnergySavingMode(() => toggleEnergySavingMode())"
                      :value="connectionStates.is_power_saving_mode_activated"
                      :class="connectionStates.is_power_saving_mode_activated ? 'bg-orange-100' : 'bg-teal-900'"
                      class="relative h-4 w-8 mx-2 shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-100 ease-in-out">
                    <span class="sr-only">global sleep mode</span>
                    <span
                        aria-hidden="true"
                        :class="connectionStates.is_power_saving_mode_activated ? 'translate-x-2' : '-translate-x-2'"
                        class="inline-block h-4 w-4 transform rounded-full -translate-y-0.5 bg-white shadow-lg transition duration-100 ease-in-out"
                    />
                  </Switch>
                </div>
              </li>
            <li v-if="!defaultVehicle.length && hardVehicleRestriction">
              <span class="color_enabled">{{ 'WARNING! NO DEFAULT VEHICLE SPECIFIED, BUT HARD RESTRICTION ENABLED. PLEASE DISABLE HARD VEHICLE RESTRICTION OR SPECIFY THE DEFAULT VEHICLE ID' }}</span>
            </li>
            <li v-if="defaultVehicle.length && vehicleStates?.has(defaultVehicle)">
              <vehicle-short-view :vehicleId="defaultVehicle" :vehicleState="vehicleStates.get(defaultVehicle)"
                                  :nodes-lookup="visibleNodes" :initiallyExpanded="true"/>
            </li>
            <li v-if="!hardVehicleRestriction" v-for="vehicle_id in Array.from(vehicleStates?.keys()).sort()">
              <vehicle-short-view v-if="!defaultVehicle.length || defaultVehicle !== vehicle_id" :vehicleId="vehicle_id" :vehicleState="vehicleStates.get(vehicle_id)"
                                  :nodes-lookup="visibleNodes"/>
            </li>
          </ul>
          <new-order-modal v-if="editorState.newOrderOpen"
                           @closeModal="editorState.newOrderOpen=false; editorState.newOrderData = {nodes: [], title: null, description: null, category: null}"
                           :orderData="editorState.newOrderData"
                           :vehicles="vehicleStates"/>
        </main>
      </div>
      <DialogPopup ref="dialogPopup"></DialogPopup>
</template>

<script setup lang="ts">
import {CheckIcon, ChevronUpDownIcon,} from '@heroicons/vue/20/solid'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch} from '@headlessui/vue'</script>


<script lang="ts">
import {defineComponent} from 'vue'
import panZoom from 'panzoom';
import { publishControlCenterMessage } from '@/network/MqttManager';
import {useConfig} from "@/main"
import {CanvasHelper, LiveViewHelper} from '@/helpers/LiveViewHelper'
import * as ApiManager from "../network/ApiManager";
import {arrToXY, prod_safe_log} from '@/utils'
import {EdgeState, VehicleState, vehicleStates} from '@/dtos/VehicleState'
import VehicleShortView from "@/components/liveview/VehicleInfoShort.vue";
import NewNodeModal from "@/components/liveview/NewNodeModal.vue"
import NewEdgeModal from "@/components/liveview/NewEdgeModal.vue"
import {TrajectoryData} from '@/helpers/TrajectoryHelper';
import * as AlertManager from '@/datamanagers/AlertsManager'
import {liveViewEditorState} from "@/dtos/AppState";
import NewOrderModal from "@/components/liveview/NewOrderModal.vue";
import {graphDebugState, currentUserState} from "@/datamanagers/ReactiveStates";
import { NodesManager } from "@/datamanagers/NodesManager";
import { EdgesManager } from "@/datamanagers/EdgesManager";
import { connectionStates } from "@/datamanagers/StateIndicatorsManager";
import DialogPopup from '@/components/DialogPopup.vue';

export default defineComponent({
  name: "LiveView",
  data() {
        return {
          show_node_id: useConfig().config.configuration.show_node_id,
          vehicleStates,
          liveViewHelper: new LiveViewHelper(useConfig().config.configuration.maps[useConfig().config.configuration.default_map]),
          editorState: liveViewEditorState,
          panzoom: null as any,
          map_configs: useConfig().config.configuration.maps,
          map_config: useConfig().config.configuration.maps[useConfig().config.configuration.default_map],
          current_map: useConfig().config.configuration.maps[useConfig().config.configuration.default_map],
          timestamp: Date.now(),
          zoom_scale: 1,
          canvasContext: null as any,
          showAddNode: false,
          showAddEdge: false,
          selectedNode: null as any,
          selectedNodes: new Array(),
          selectedEdge: null as any,
          selectedEdges: new Array(),
          visibleNodes: new Set<number>(),
          isNodesLoadingInProgress: false,
          isAllNodesLoaded: false,
          visibleEdges: new Set<number>(),
          isEdgesLoadingInProgress: false,
          isAllEdgesLoaded: false,
          cachedEdgesTrajectories: new Map<number, any>(),
          graphDebugState,
          showVehicleLabels: useConfig().localConfig.showVehicleLabels ?? true,
          showNodeLabels: useConfig().localConfig.showNodeLabels ?? true,
          markOccupiedNodesEdges: useConfig().localConfig.markOccupiedNodesEdges ?? true,
          mapDataRedrawFlags: {
            isVehiclesDirty: true,
            isNodesDirty: true,
            isEdgesDirty: true,
          },
          connectedComponents: new Map(),
          selectedComponents: new Map(),
          defaultVehicle: useConfig().localConfig.default_vehicle ? String(useConfig().localConfig.default_vehicle) : '',
          hardVehicleRestriction: useConfig().localConfig.hard_vehicle_restriction || false
        };
    },
    components: {
      NewOrderModal,
      NewNodeModal,
      NewEdgeModal,
      VehicleShortView,
    },
    methods: {
        switchMap() {
          console.log("switched to map", this.current_map);
          this.visibleNodes.clear();
          this.visibleEdges.clear();
          this.cachedEdgesTrajectories.clear();
          this.mapDataRedrawFlags.isEdgesDirty = true;
          this.mapDataRedrawFlags.isNodesDirty = true;
          this.selectedEdge = null;
          this.selectedNode = null;
          this.liveViewHelper.switchmap(this.current_map, this.startDrawing);
          this.getGraph();
        },
        initCanvasMap() {
          var c = document.getElementById("myCanvas") as HTMLCanvasElement;
          var ctx = c.getContext("2d") as CanvasRenderingContext2D;
          this.canvasContext = ctx;

          const ch = new CanvasHelper(ctx);
          this.liveViewHelper.init(window.innerWidth/2.4, window.innerWidth, ch);
          this.liveViewHelper.canvas_height = window.innerWidth / 2.4;
          this.liveViewHelper.canvas_width = window.innerWidth;
        },
        initPanZoom(){
          var elem = document.getElementById('myCanvas') as any;
          let onDoubleClick = this.onDoubleClick
          let onMouseClick = this.onClick
          let getEventCoord = this.getEventCoordinates
          this.panzoom = panZoom(elem, {
            onDoubleClick: function (e: Event) {
              let clicked_pose = getEventCoord(e, elem)
              onDoubleClick(clicked_pose)
              return true; // tells the library to not preventDefault, and not stop propagation
            },
            beforeMouseDown(e) {
              var xy = getEventCoord(e, elem)
              return onMouseClick(xy)
            },
            // disables zoom on double click
            zoomDoubleClickSpeed: 1,
            smoothScroll: true,
            zoomSpeed: 0.05,
            bounds: true,
            boundsPadding: 0.1
          });
          this.panzoom.on('zoom', (e: any) => {
            this.zoom_scale = e.getTransform().scale
          });
        },
      drawVehicle(position: any, size:{ w: number, l: number}, name: string | null = "", isDefault?: boolean) {
        const canvasPos = this.liveViewHelper.realWorldToCanvasCoord(position);

        if (canvasPos == null) return;
        const adjustedSize = {
          w: size.w * this.liveViewHelper.canvas_height / this.liveViewHelper.mapConfig.map_real_size[1],
          l: size.l * this.liveViewHelper.canvas_height / this.liveViewHelper.mapConfig.map_real_size[1]
        };

        const transparency = this.editorState.editMode || !isDefault ? 0.5 : 1;

        this.liveViewHelper.drawRectangle(canvasPos, adjustedSize, this.current_map["map_rotate"], `rgba(32, 45, 21, ${transparency * 0.8}`, this.zoom_scale);
        this.liveViewHelper.drawDirectionArrow(canvasPos, adjustedSize, this.current_map["map_rotate"], `rgba(255, 165, 0, ${transparency}`, this.zoom_scale);
      },
      drawVehicleLabel(position: any, name: string | null = "") {
        const canvasPos = this.liveViewHelper.realWorldToCanvasCoord(position);
        if (canvasPos == null) return;
        this.liveViewHelper.drawText(canvasPos, `${name}`, this.current_map["map_rotate"], 'blue', true, this.zoom_scale);
      },
      displayNodes() {
        this.visibleNodes.forEach((nodeId: number) => {
          if (!NodesManager.nodes.has(nodeId)) {
            console.log('Missing Node data for: ', nodeId);
            return;
          }
          const node = NodesManager.nodes.get(nodeId);
          const pos = this.liveViewHelper.realWorldToCanvasCoord(node.nodePosition);
          if (pos == null) return;
          if (node.charging_station)
            this.liveViewHelper.drawPoint(pos, 'green', this.zoom_scale / 2);
          else if (node.idle_station)
            this.liveViewHelper.drawPoint(pos, 'purple', this.zoom_scale / 2);
          else if (this.selectedNode === null || !this.editorState.editMode || this.selectedNode.nodeId !== nodeId)
            this.liveViewHelper.drawPoint(pos, undefined, this.zoom_scale);
          // @ts-ignore
          if (graphDebugState.nodes[nodeId]?.length > 0) this.liveViewHelper.drawPoint(pos, 'yellow', this.zoom_scale);
        })
        if (this.selectedNode != null && this.editorState.editMode) {
          var pos = this.liveViewHelper.realWorldToCanvasCoord(this.selectedNode.nodePosition);
          if (pos == null) return;
          this.liveViewHelper.drawPoint(pos, 'orange', this.zoom_scale);
        }
      },
      drawNodesLabels() {
        this.visibleNodes.forEach((nodeId: number) => {
          const node = NodesManager.nodes.get(nodeId);
          var pos = this.liveViewHelper.realWorldToCanvasCoord(node.nodePosition);
          if (pos == null) return;
          if (this.show_node_id) {
            this.liveViewHelper.drawText(pos, "Node" + ` ${nodeId}`, this.current_map["map_rotate"], undefined, undefined, this.zoom_scale);
          } else if (node.nodeDescription?.length) {
            this.liveViewHelper.drawText(pos, node.nodeDescription, this.current_map["map_rotate"], undefined, undefined, this.zoom_scale);
          }
        });
        if (this.selectedNode != null && this.editorState.editMode) {
          var pos = this.liveViewHelper.realWorldToCanvasCoord(this.selectedNode.nodePosition);
          if (pos == null) return;
          if (this.show_node_id) {
            if (this.selectedNode?.nodeId) {
              this.liveViewHelper.drawText(pos, "Node" + ` ${this.selectedNode?.nodeId}`, this.current_map["map_rotate"], 'orange', undefined, this.zoom_scale);
            } else {
              this.liveViewHelper.drawText(pos,
                  `[${parseFloat(this.selectedNode.nodePosition.x).toFixed(2)}, ${parseFloat(this.selectedNode.nodePosition.y).toFixed(2)}]`,
                  this.current_map["map_rotate"], undefined, undefined, this.zoom_scale);
            }
          } else {
            if (this.selectedNode?.nodeDescription) { //Check if nodeDescription is defined
              this.liveViewHelper.drawText(pos, this.selectedNode.nodeDescription, this.current_map["map_rotate"], 'orange', undefined, this.zoom_scale);
              //   show coordinates only for newly created node
            } else if (!this.selectedNode.nodeId) {
              this.liveViewHelper.drawText(pos,
                  `[${parseFloat(this.selectedNode.nodePosition.x).toFixed(2)}, ${parseFloat(this.selectedNode.nodePosition.y).toFixed(2)}]`,
                  this.current_map["map_rotate"], undefined, undefined, this.zoom_scale);
            }
          }
        }
      },
      displayOrderData() {
        liveViewEditorState.newOrderData.nodes.forEach((node: any) => {
          const nodeId = node.nodeId;

          if (!NodesManager.nodes.has(nodeId)) return;
          const cachedNode = NodesManager.nodes.get(nodeId)
          const pos = this.liveViewHelper.realWorldToCanvasCoord(cachedNode.nodePosition);
          if (pos == null) return;

          this.liveViewHelper.drawPoint(pos, '#0f0', this.zoom_scale);
        })
      },
      displayEdges() {
        let opacity = 1;
        this.visibleEdges.forEach((edgeId: number) => {
          // to display edge as straight dashed line
          // var startPos = this.visibleNodes.get(edge.startNodeId)?.nodePosition
          // var endPos = this.visibleNodes.get(edge.endNodeId)?.nodePosition
          // if (startPos != undefined && endPos != undefined) {
          //   startPos = this.liveViewHelper.realWorldToCanvasCoord(startPos)
          //   endPos = this.liveViewHelper.realWorldToCanvasCoord(endPos)
          //   this.liveViewHelper.drawLine([startPos, endPos], 0, 1, "black", 8)
          // }
          // console.log(edge.edgeId)

          if (!EdgesManager.edges.has(edgeId)) {
            console.log('missing edge data for: ', edgeId)
            return;
          }
          const edge = EdgesManager.edges.get(edgeId);
          const trajectory = EdgesManager.trajectories.get(edgeId);

          if (this.editorState.editedConnectedComponent !== null) {
            opacity = this.editorState.editedConnectedComponent.edges.indexOf(edge.edgeId) > -1 ? 1 : 0.3;
          }

          // @ts-ignore
          if (graphDebugState.edges[edge.edgeId]?.length > 0) {
            this.drawTrajectory(trajectory, false, "yellow", edge.edgeId, opacity);
          } else if (this.selectedEdge == null || this.selectedEdge.edgeId !== edge.edgeId) {
            this.drawTrajectory(trajectory, false, undefined, edge.edgeId, opacity);
          }
        });
        if (this.selectedEdge !== null) {
          const trajectory = EdgesManager.trajectories.get(NaN);
          if(trajectory?.fittedPoints?.length){
            if (this.editorState.editedConnectedComponent !== null) {
              opacity = this.editorState.editedConnectedComponent.edges.indexOf(this.selectedEdge.edgeId) > -1 ? 1 : 0.3;
            }
            this.drawTrajectory(trajectory, true, 'orange', NaN, opacity);
          }
        }
      },
      displayVehicles(){
        this.vehicleStates.forEach((value: VehicleState, key: string, map: any) => {
            const mapId = value.agvPosition?.mapId ?? 0;
            //@ts-ignore
            if (mapId.toString() == this.current_map.map_id && value.show_on_map && value.agvPosition.x !== undefined) {
              const is_default_vehicle = !useConfig().localConfig.default_vehicle || useConfig().localConfig.default_vehicle == value.serialNumber ? true : false;
              this.drawVehicle(value.agvPosition, { w: value.factsheet?.physicalParameters.width || 1, l: value.factsheet?.physicalParameters.length || 1 }, (value?.name ?? "") + '-' + key, is_default_vehicle);
            }
          })
        for (let vehiclesKey in this.graphDebugState.vehicles) {
          this.drawSafetyBox(this.graphDebugState.vehicles[vehiclesKey]);
        }
      },
      drawVehicleLabels(){
        this.vehicleStates.forEach((value: VehicleState, key: string, map: any) => {
            const mapId = value.agvPosition?.mapId ?? 0
            //@ts-ignore
            if (mapId.toString() == this.current_map.map_id && value.show_on_map && value.agvPosition.x !== undefined) {
              this.drawVehicleLabel(value.agvPosition, (value?.name ?? "") + '-' + key);
            }
          });
      },
      drawSafetyBox(vehicleData: any) {
        if (vehicleData?.box)
          this.liveViewHelper.drawSafetyBox(vehicleData?.box, 'black', this.zoom_scale)
        if (vehicleData?.safety_box)
          this.liveViewHelper.drawSafetyBox(vehicleData?.safety_box, 'green', this.zoom_scale)
      },
      drawProcessedNodesAndEdges() {
        this.vehicleStates.forEach((value: VehicleState, key: string, map: any) => {
          const mapId = value.agvPosition?.mapId ?? 0
          if (mapId.toString() == this.current_map.map_id){

            const closestEdge = value.edgeStates?.at(0);
            const closestNode = value.nodeStates?.at(0);

            if (!closestEdge && !closestNode) return;

            if (!closestEdge) {
              if (!!closestNode) {
                const pos = this.liveViewHelper.realWorldToCanvasCoord(closestNode.node_position);
                if (pos != null) {
                  this.liveViewHelper.drawPoint(pos, 'red', this.zoom_scale);
                }
              }
            } else if (!closestNode || (!!closestNode && closestEdge.sequenceId < closestNode.sequence_id)) {
              let opacity = 1;
              if (this.editorState.editedConnectedComponent !== null) {
                opacity = this.editorState.editedConnectedComponent.edges.indexOf(Number(closestEdge.edgeId)) > -1 ? 1 : 0.3;
              }
              // edit to display current trajectory of each vehicle
              const trajectory = new TrajectoryData();
              const edgeColor = closestEdge.released ? 'red' : '#ff8093';
              const apiTrajectory = closestEdge.trajectory;
              trajectory.inputPoints = [];
              const curve = LiveViewHelper.createCurveFromApi(apiTrajectory);
              if (curve != null && apiTrajectory != null) {
                trajectory.nurbsCurveVda = apiTrajectory;
                trajectory.addCurve(curve);
              }
              this.drawTrajectory(trajectory, false, edgeColor, undefined, opacity);
            } else if (!!closestNode) {
              const pos = this.liveViewHelper.realWorldToCanvasCoord(closestNode.node_position);
              if (pos != null) {
                this.liveViewHelper.drawPoint(pos, 'red', this.zoom_scale);
              }
            }
          }
        })
      },
      startDrawing() {
        this.liveViewHelper.initBaseMap()
        var draw = () => {
          if (Date.now() < (this.timestamp + 300)) return requestAnimationFrame(draw);
          this.canvasContext.save()
          // after .rebaseContext realWorld coordinates are displayed on canvas correctly
          this.liveViewHelper.rebaseContext(this.zoom_scale)
          this.displayVehicles();
          this.displayNodes();
          this.displayEdges();
          if (this.markOccupiedNodesEdges)
            this.drawProcessedNodesAndEdges();

          if (this.showVehicleLabels)
            this.drawVehicleLabels();

          if (this.showNodeLabels)
            this.drawNodesLabels();

          if (liveViewEditorState.newOrderOpen)
            this.displayOrderData();

          this.mapDataRedrawFlags.isVehiclesDirty = false;
          this.mapDataRedrawFlags.isNodesDirty = false;
          this.mapDataRedrawFlags.isEdgesDirty = false;

          this.canvasContext.restore()
          this.timestamp = Date.now();
          return requestAnimationFrame(draw);
        }
        draw();
      },
      getEventCoordinates(e:Event, element:any){
        let bound = element.getBoundingClientRect();
        const scale = this.panzoom.getTransform().scale
        const yScroll = document.getElementsByTagName('*')[0]?.scrollTop
        // @ts-ignore
        var clicked_pose = [(e.pageX - bound.left) / scale, (e.pageY - (bound.top + yScroll) ) / scale  ]
        return clicked_pose
      },
      setCachedTrajectory(trajectoryId: number, points: Object[]) {
        if (trajectoryId) {
          const cacheTrajectory = {
            points,
            valid: true,
          };
          this.cachedEdgesTrajectories.set(trajectoryId, cacheTrajectory)
        }
      },
      drawTrajectory(trajectory: TrajectoryData, selected: boolean, color: string = 'blue', trajectoryCacheId: number | undefined, opacity: number = 1) {
        let fittedPoints = trajectory?.fittedPoints;

        if (!fittedPoints || !fittedPoints.length) return;

        let points: any;

        // check if there are cached edge coordinates and they are still valid
        let cachedTrajectory;
        if (trajectoryCacheId) {
          cachedTrajectory = this.cachedEdgesTrajectories.get(trajectoryCacheId);
        }

        if (cachedTrajectory !== undefined && cachedTrajectory.valid && !this.mapDataRedrawFlags.isEdgesDirty) {
          points = cachedTrajectory.points;
        } else {
          // console.info('cache is invalid, recalculating');
          points = fittedPoints.map((el: any) => this.liveViewHelper.realWorldToCanvasCoord(el)).map((e: any) => {
            return {x: e.x, y: e.y}
          });
          if (trajectoryCacheId) {
            this.setCachedTrajectory(trajectoryCacheId, points);
          }
        }
        const lineWidth = useConfig().localConfig.base_line_width || 1;
        this.liveViewHelper.drawLine(points, 1, 0, color, 0, this.zoom_scale, opacity, lineWidth)
        const from = points?.at(-10) ?? points[0]
        const to = points.at(-1)
        if (from != undefined && to != undefined) {
          this.liveViewHelper.drawArrow(from, to, color, this.zoom_scale, opacity, lineWidth)
        }
        if (selected) {
          // to display input point
          points = trajectory.inputPoints
          points = points.map((el: any) => this.liveViewHelper.realWorldToCanvasCoord(el)).map((e: any) => {
            return {x: e.x, y: e.y}
          })
          points.forEach((p: any) => this.liveViewHelper.drawPoint(p, color, this.zoom_scale))

          // to display control points
          // points = trajectory?.nurbsCurve?.points
          // if (points!=null){
          //   points = points.map((el:any)=>this.liveViewHelper.realWorldToCanvasCoord(arrToXY(el))).map((e:any)=>{return {x:e.x, y:e.y}})
          //   points.forEach((p:any)=> this.liveViewHelper.drawPoint(p, 'green'))
          // }
        }
      },
      closeEditNodeModal() {
        this.showAddNode=false;
        this.selectedNode=null;
      },
      closeEditEdgeModal() {
        this.selectedEdges.map((edgeId: any) => {
          if (this.cachedEdgesTrajectories.has(edgeId)) {
            this.cachedEdgesTrajectories.get(edgeId).valid = false;
          }
        });
        this.visibleEdges.delete(NaN);
        this.cachedEdgesTrajectories.delete(NaN);
        EdgesManager.delete(NaN);
        this.selectedEdge=null;
        this.showAddEdge=false;
        this.editorState.editingTrajectory=false;
        this.editorState.editedConnectedComponent = null;
        this.selectedComponents.clear();
      },
      onNodeUpdated(args?: any) {
        prod_safe_log("fetched updated node");
        if (args) {
          const data = JSON.parse(args.data);
          const msg = JSON.parse(data.message);
          NodesManager.update(msg.payload);
          if (msg.payload.nodePosition.mapId == this.current_map.map_id) {
            this.visibleNodes.add(Number(msg.node_id));
          } else {
            this.visibleNodes.delete(Number(msg.node_id));
          }
        }
        setTimeout(() => {this.getGraph()}, 0); // Timeout puts function execution to the end of the callback queue
      },
      onNodeDeleted(args?: any) {
        prod_safe_log("fetched deleted node");
        if (args) {
          const data = JSON.parse(args.data);
          const msg = JSON.parse(data.message);
          NodesManager.delete(Number(msg.node_id));
          this.visibleNodes.delete(Number(msg.node_id));
        }
        setTimeout(() => {this.getGraph()}, 0); // Timeout puts function execution to the end of the callback queue
      },
      onEdgeUpdated(args?: any) {
        prod_safe_log("fetched updated edge");
        if (args) {
          const data = JSON.parse(args.data);
          const msg = JSON.parse(data.message);
          EdgesManager.update(msg.payload);
          if (this.visibleNodes.has(msg.startNodeId) && this.visibleNodes.has(msg.endNodeId)) {
            this.visibleEdges.add(Number(msg.edge_id));
          } else {
            this.visibleEdges.delete(Number(msg.edge_id));
          }
          this.cachedEdgesTrajectories.delete(msg.edge_id);
        }
        setTimeout(() => {this.getGraph()}, 0); // Timeout puts function execution to the end of the callback queue
      },
      onEdgeDeleted(args?: any) {
        prod_safe_log("fetched deleted edge");
        if (args) {
          const data = JSON.parse(args.data);
          const msg = JSON.parse(data.message);
          EdgesManager.delete(Number(msg.edge_id));
          this.cachedEdgesTrajectories.delete(Number(msg.edge_id));
          this.visibleEdges.delete(Number(msg.edge_id));
        }
        setTimeout(() => {this.getGraph()}, 0); // Timeout puts function execution to the end of the callback queue
      },
      getNodesFromApi(page?: number, page_size?: number) {
        const params = {
          page: page || 1,
          page_size: page_size || 50
        };
        return new Promise((resolve, reject) => {
          ApiManager.getNodes(
            () => {
              // @ts-ignore
              AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_nodes"), this.$t("str_try_again")))
              prod_safe_log('nodes could not be requested')
              reject('nodes could not be requested');
            },
            (data: any) => {
              resolve(data);
            },
            params
          );
        });
      },
      getEdgesFromApi(page?: number, page_size?: number) {
        const params = {
          page: page || 1,
          page_size: page_size || 50
        };
        return new Promise((resolve, reject) => {
          ApiManager.getEdges(
            () => {
              // @ts-ignore
              AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_edges"), this.$t("str_try_again")))
              prod_safe_log('edges could not be requested')
              reject('edges could not be requested');
            },
            (data: any) => {
              resolve(data)
            },
            params
          );
        });
      },
      getGraph: async function() {
        let nodes: any = [];
        let edges: any = [];

        if (NodesManager.nodes.size && this.isAllNodesLoaded) {
          // use cached nodes
          nodes = Array.from(NodesManager.nodes, ([key, value]) => structuredClone(value));
          nodes = nodes.filter((node: any) => node.nodePosition.mapId == this.current_map.map_id);
          if (nodes.length) {
            nodes.forEach((node: any) => {
              this.visibleNodes.add(node.nodeId);
            });
          }
          this.isAllNodesLoaded = true;
        } else if (!this.isNodesLoadingInProgress){
          // get nodes from the API
          this.isNodesLoadingInProgress = true;
          let page = 1;
          const page_size = useConfig().localConfig.nodes_pagination || useConfig().config.configuration.nodes_pagination || 50;

          const response: any = await this.getNodesFromApi(page, page_size);
          this.visibleNodes.clear();
          NodesManager.clear();
          response.nodes.map((node: any) => {
            if (node.nodePosition.mapId == this.current_map.map_id) {
              this.visibleNodes.add(node.nodeId);
            }
            NodesManager.add(structuredClone(node))
          });

          while (response.length > page*page_size) {
            page++;
            const response: any = await this.getNodesFromApi(page, page_size);
            response.nodes.map((node: any) => {
              NodesManager.add(structuredClone(node));
              if (node.nodePosition.mapId == this.current_map.map_id) {
                this.visibleNodes.add(node.nodeId);
              }
            });
          }
          if (response.length <= page*page_size){
            this.isNodesLoadingInProgress = false;
            this.isAllNodesLoaded = true;
          }
        }
        if (EdgesManager.edges.size && this.isAllEdgesLoaded) {
          // user cached edges
          edges = Array.from(EdgesManager.edges, ([key, value]) => value);
          edges = edges.filter((edge: any) => this.visibleNodes.has(edge.startNodeId) && this.visibleNodes.has(edge.endNodeId));
          if (edges.length) {
            edges.forEach((edge: any) => {
              if (!!edge.edgeId)
                this.visibleEdges.add(edge.edgeId);
            });
          }
        } else if (!this.isEdgesLoadingInProgress) {
          this.isEdgesLoadingInProgress = true;
          // get edges from the API.
          let page = 1;
          const page_size = useConfig().localConfig.edges_pagination || useConfig().config.configuration.edges_pagination || 50;

          const response: any = await this.getEdgesFromApi(page, page_size);
          this.visibleEdges.clear();
          EdgesManager.clear();
          if (response.edge.length) {
            response.edge.map((edge: any) => {
              EdgesManager.add(edge);
              if (this.visibleNodes.has(edge.startNodeId) && this.visibleNodes.has(edge.endNodeId)){
                this.visibleEdges.add(edge.edgeId);
              } else {
                this.visibleEdges.delete(Number(edge.edgeId));
              }
            });
          }

          while (response.length > page*page_size) {
            page++;
            const response: any = await this.getEdgesFromApi(page, page_size);
            response.edge.map((edge: any) => {
              // const processedEdge = this.processEdgeBeforeCaching(edge);
              EdgesManager.add(edge);
              if (this.visibleNodes.has(edge.startNodeId) && this.visibleNodes.has(edge.endNodeId)){
                this.visibleEdges.add(edge.edgeId);
              } else {
                this.visibleEdges.delete(Number(edge.edgeId));
              }
            });
          }
          if (response.length <= page*page_size){
            this.isEdgesLoadingInProgress = false;
            this.isAllEdgesLoaded = true;
          }
        }
      },
      onDoubleClick(clicked_pose: number[]) {
        // !!! to check if the transforms work correct. Click on the known location and check that it matches clickedPoseRW
        // !!! Then p should match clickedPoseCanvas for correct inverse transform
        // const p = arrToXY(clicked_pose)
        // console.log("canvas p ", p)
        // const clickedPoseRW = this.liveViewHelper.canvasToRealWorldCoordinates(p)
        // console.log("Real world p, ", clickedPoseRW.x.toFixed(2), clickedPoseRW.y.toFixed())
        // const clickedPoseCanvas = this.liveViewHelper.realWorldToCanvasCoord(clickedPoseRW)
        // console.log("Canvas again p, ", clickedPoseCanvas)
        if (this.editorState.editedConnectedComponent) return;

        const realWorldPos = this.liveViewHelper.canvasToRealWorldCoordinates(arrToXY(clicked_pose))
        const selectedNode = LiveViewHelper.isMouseOver(realWorldPos,
            Array.from(this.visibleNodes.values()).map((nodeId:number) => NodesManager.nodes.get(nodeId).nodePosition), 0.2/this.zoom_scale)

        if (!selectedNode.length && this.editorState.editMode && !this.editorState.editingTrajectory) {
          this.showAddNode = true;
          this.selectedNode = {nodePosition: realWorldPos};
          this.selectedEdge = null;
          this.showAddEdge = false;
        } else if (this.editorState.editingTrajectory) {
          const trajectory = EdgesManager.trajectories.get(NaN);
          let pointId = trajectory.isMouseOverPoint(realWorldPos, 0.2/this.zoom_scale);
          if (pointId === -1) {
            trajectory.addPoint(realWorldPos);
            console.log('addPoint');
          }
          else if (pointId !== 0 && pointId !== trajectory.inputPoints.length - 1) {
            trajectory.deletePoint(pointId);
            console.log('deletePoint');
          }
          LiveViewHelper.FitTrajectory(trajectory, this.selectedEdge)
            .then((edge: any) => {
              this.cachedEdgesTrajectories.delete(NaN);
            });
          // this.liveViewHelper.smoothEdge(this.selectedEdge)
        }
      },
      selectNode(nodes:any){
        this.showAddNode = true;
        this.selectedNode = structuredClone(NodesManager.nodes.get(nodes[0]));
        this.selectedNodes = structuredClone(nodes);
        this.selectedEdge = null;
        this.showAddEdge = false;
        this.editorState.editingTrajectory = false;
        this.editorState.newOrderOpen = false;
      },
      selectEdge(edgeId:any, endNode?: any){
        let edge;
        if (Number.isNaN(edgeId)) { // create new edge if NaN provided as ID, endNode is necessary here to calculate new trajectory
          const newTrajectory = new TrajectoryData();
          newTrajectory.initTrajectory(this.selectedNode.nodePosition, endNode.nodePosition);
          edge = {
            startNodeId: this.selectedNode.nodeId,
            endNodeId: endNode.nodeId,
            trajectory: newTrajectory,
          };
        } else {
          edge = structuredClone(EdgesManager.edges.get(edgeId));
        }
        this.editorState.capturingEndNode = false;
        if (this.selectedEdge != null && this.selectedEdge.edgeId != edge.edgeId){
          this.editorState.editingTrajectory = false;
        }
        this.selectedEdge = edge;
        this.showAddEdge = true;
        this.selectedEdge.trajectory.inputPoints = structuredClone(edge.trajectory.inputPoints);
        EdgesManager.setEditedEdge(this.selectedEdge);
        this.visibleEdges.add(NaN); //Edited edge is always one and is a clone of existing one or a virtual one in case of a newly created
        const filteredConnectedComponent = new Map();
        for (const [key, value] of this.connectedComponents) {
          if (value.includes(edge.edgeId)) {
            filteredConnectedComponent.set(key, value);
          }
        }
        if (filteredConnectedComponent.size) {
          this.selectedComponents = filteredConnectedComponent;
        } else {
          this.selectedComponents = new Map();
        };
        this.showAddNode = false
        this.selectedNode = null
        this.editorState.newOrderOpen = false
        if (this.selectedEdge.trajectory.inputPoints.length == 0) {
          this.selectedEdge.trajectory.initTrajectory(NodesManager.nodes.get(edge.startNodeId).nodePosition,
          NodesManager.nodes.get(edge.endNodeId).nodePosition);
          LiveViewHelper.FitTrajectory(this.selectedEdge.trajectory, this.selectedEdge)
            .then(() => this.cachedEdgesTrajectories.delete(NaN));
        }
        const trajectory = EdgesManager.trajectories.get(NaN);
        LiveViewHelper.FitTrajectory(trajectory, this.selectedEdge)
          .then(() => this.cachedEdgesTrajectories.delete(NaN));
      },
      onClick(xy: number[]) {
        if (!this.editorState.editMode && !this.editorState.newOrderOpen) return;
        const realWorldPos = this.liveViewHelper.canvasToRealWorldCoordinates(arrToXY(xy))
        const tempNodeArray = Array.from(this.visibleNodes.values());
        let selectedNodes = [] as number[];
        if (!this.editorState.editedConnectedComponent){
          selectedNodes = LiveViewHelper.isMouseOver(realWorldPos,
              tempNodeArray.map((nodeId: number) => NodesManager.nodes.get(nodeId).nodePosition),
              0.4 / this.zoom_scale);
        }
        if (selectedNodes.length>0 && this.editorState.editMode){
          if (this.editorState.capturingEndNode) {
            const endNode = NodesManager.nodes.get(Number(tempNodeArray[selectedNodes[0]]));
            this.editorState.capturingEndNode = false;
            this.selectEdge(NaN, endNode);
          } else if (!this.editorState.editingTrajectory) {
            //this.selectNode(tempNodeArray[selectedNode])
            const result = new Array();
            selectedNodes.forEach(i => result.push(tempNodeArray[i]));
            this.selectNode(result);
          }
        } else if (selectedNodes.length>0 && this.editorState.newOrderOpen){
          const orderNode = NodesManager.nodes.get(Number(tempNodeArray[selectedNodes[0]]));
          this.editorState.newOrderData.nodes.push({
              nodeId: orderNode.nodeId,
              nodeDescription: orderNode.nodeDescription,
              defaultActions: true
            });
        } else if (this.editorState.editMode && !this.editorState.editingTrajectory){
          const selectedEdges = [] as number[];
          this.visibleEdges.forEach((edgeId: number) => {
            if (Number.isNaN(edgeId)) // Skip edited edge from puting it to selection
              return;
            const trajectory = EdgesManager.trajectories.get(edgeId);
            if (!trajectory)
              console.log('Missing trajectory for ', edgeId);
            if (trajectory.isMouseOverTrajectory(realWorldPos, 0.2/this.zoom_scale)) {
              selectedEdges.push(edgeId);
            }
          })
          if (this.editorState.editedConnectedComponent !== null) {
            selectedEdges.forEach((edgeId: any) => {
              if (!edgeId) return;
              //@ts-ignore
              const selectedEdgeIndex = this.editorState.editedConnectedComponent.edges.indexOf(edgeId);
              if (selectedEdgeIndex < 0) {
                //@ts-ignore
                this.editorState.editedConnectedComponent.edges.push(edgeId);
              } else {
                //@ts-ignore
                this.editorState.editedConnectedComponent.edges.splice(selectedEdgeIndex, 1);
              }
            });
          } else {
            if (this.selectedEdge) {
              this.cachedEdgesTrajectories.get(this.selectedEdge.edgeId).valid = false;
            }
            if (selectedEdges.length)
              this.selectEdge(selectedEdges[0]);
            this.selectedEdges = selectedEdges;
          }
        }
        if (this.editorState.editingTrajectory && this.selectedEdge != null && this.editorState.editMode) {
          const trajectory = EdgesManager.trajectories.get(NaN);
          const id = trajectory.isMouseOverPoint(realWorldPos, 0.2/this.zoom_scale);
          trajectory.isDragingPoint = id !== -1 && id !== 0 && id !== trajectory.inputPoints.length - 1;
          trajectory.draggingPointId = id;
        }
        // allow mouse-down panning if the user clicked outside the trajectory points, nodes
        // console.log(selectedNode!=-1, selectedEdge != -1, this.selectedEdge!=null ,this.editorState.editingTrajectory===true , this.selectedEdge?.trajectory?.isDragingPoint, this.editorState.editMode === true)
        const editedEdgeTrajectory = EdgesManager.trajectories.get(NaN);
        const shouldIgnore = (selectedNodes.length > 0 ||
                (this.selectedEdge != null && this.editorState.editingTrajectory
                  && editedEdgeTrajectory?.isDragingPoint !== undefined
                  && editedEdgeTrajectory?.isDragingPoint))
            && this.editorState.editMode;
        return shouldIgnore;
      },
      selectNodeFromStack(index: number) {
        this.selectedNode = structuredClone(NodesManager.nodes.get(this.selectedNodes[index]));
      },
      selectEdgeFromStack(index: number) {
        this.cachedEdgesTrajectories.get(this.selectedEdge.edgeId).valid = false;
        this.selectEdge(this.selectedEdges.at(index));
      },
      getConnectedComponentsFromApi() {
        if (currentUserState?.username && !currentUserState?.username?.length) return;
        ApiManager.getAllConnectedComponents(() => {
          // @ts-ignore
          AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_edges"), this.$t("str_try_again")))
          prod_safe_log('connected components could not be requested')
        },
        (response: any) => {
          response.data.connected_components.forEach((component: any) => {
            this.connectedComponents.set(component.id, component.edges);
          });
        });
      },
      createConnectedComponent(edges: number[]){
        ApiManager.createConnectedComponent({edges}, (err: any) => {}, (res: any) => {
          this.connectedComponents.set(res.data.connected_component.id, edges);
          this.selectedComponents.set(res.data.connected_component.id, edges);
        });
      },
      updateConnectedComponent(component_id: number, edges: number[]){
        ApiManager.updateConnectedComponent({component_id, edges}, (err: any) => {}, (res: any) => {
          this.connectedComponents.set(res.data.connected_component.id, res.data.connected_component.edges);
          this.selectedComponents.set(res.data.connected_component.id, res.data.connected_component.edges);
        });
      },
      deleteConnectedComponent(component_id: number){
        ApiManager.deleteConnectedComponent({component_id}, (err: any) => {}, (res: any) => {
          this.connectedComponents.delete(component_id);
          this.selectedComponents.delete(component_id);
        });
      },
      onConnectedComponentUpdated(res: any) {
        const message = JSON.parse(JSON.parse(res.data).message);
        this.connectedComponents.set(message.component_id, message.payload.edges);
      },
      onConnectedComponentAdded(res: any) {
        const message = JSON.parse(JSON.parse(res.data).message);
        this.connectedComponents.set(message.component_id, message.payload.edges);
      },
      onConnectedComponentDeleted(res: any) {
        const message = JSON.parse(JSON.parse(res.data).message);
        this.connectedComponents.delete(message.component_id);
      },
      toggleEnergySavingMode() {
        connectionStates.is_power_saving_mode_activated = !connectionStates.is_power_saving_mode_activated;
        const mqttConfig = useConfig().config.configuration.mqtt
        publishControlCenterMessage(
          mqttConfig,
          window.location.hostname,
          window.location.protocol,
          `${mqttConfig.emmInterfaceName}/${mqttConfig.emmVersion}/${mqttConfig.manufacturer}/${mqttConfig.ccSubtopic}/energySavingMode/control`,
          {"status": connectionStates.is_power_saving_mode_activated}
        )
      },
      async confirmEnergySavingMode(callback: Function) {
        let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
          //@ts-ignore
          title: this.$t("str_power_saving_mode"),
          //@ts-ignore
          message: connectionStates.is_power_saving_mode_activated ? this.$t("str_power_saving_off_description") : this.$t("str_power_saving_on_description"),
          //@ts-ignore
          okButton: this.$t("str_power_saving_yes"),
          okButtonType: 'button',
          //@ts-ignore
          cancelButton: this.$t("str_power_saving_cancel"),
        });
        if (confirmed) {
          callback();
        } else {
          console.log('Cancelled');
        }
      },
    },

  mounted() {
    this.initCanvasMap();
    this.initPanZoom();
    this.switchMap();
    addEventListener("mousemove", (e: MouseEvent) => {
      if (this.selectedEdge) {
        const trajectory = EdgesManager.trajectories.get(NaN);
        if (trajectory?.isDragingPoint) {
          // @ts-ignore
          const elem = document.getElementById('myCanvas') as any;
          const xy = this.getEventCoordinates(e, elem);
          const p = this.liveViewHelper.canvasToRealWorldCoordinates(arrToXY(xy))
          trajectory.movePoint(trajectory.draggingPointId, p);
        }
      }
    });
    addEventListener("mouseup", (e: MouseEvent) => {
      if (this.selectedEdge) {
        const trajectory = EdgesManager.trajectories.get(NaN);
        if (trajectory?.isDragingPoint) {
          trajectory.isDragingPoint = false;
          trajectory.draggingPointId = -1;
          LiveViewHelper.FitTrajectory(trajectory, this.selectedEdge)
            .then(() => this.cachedEdgesTrajectories.delete(NaN));
          // this.liveViewHelper.smoothEdge(this.selectedEdge)
        }
      }
    });
  },
  created() {
    this.getConnectedComponentsFromApi();
    // commented out getGraph from the created section because it will be called on initial map switch in the mounted() section.
    // this.getGraph();
    ApiManager.subscribeToUpdates([
      [ApiManager.sse_message_types.node_updated, this.onNodeUpdated],
      [ApiManager.sse_message_types.node_deleted, this.onNodeDeleted],
      [ApiManager.sse_message_types.edge_updated, this.onEdgeUpdated],
      [ApiManager.sse_message_types.edge_deleted, this.onEdgeDeleted],
      [ApiManager.sse_message_types.connected_component_edited, this.onConnectedComponentUpdated],
      [ApiManager.sse_message_types.connected_component_deleted, this.onConnectedComponentDeleted],
      [ApiManager.sse_message_types.connected_component_added, this.onConnectedComponentAdded]
    ]);
  },
  watch: {
    editorState() {
      console.log('editorState Changed');
      // set vehicles as dirty to redraw them as semi-transparent
      this.mapDataRedrawFlags.isVehiclesDirty = false;
      this.mapDataRedrawFlags.isNodesDirty = false;
      this.mapDataRedrawFlags.isEdgesDirty = false;
    },
    current_map() {
      console.log('current_map Changed');
      // This one should set all map flags as dirty to redraw the whole map.
      this.mapDataRedrawFlags.isVehiclesDirty = true;
      this.mapDataRedrawFlags.isNodesDirty = true;
      this.mapDataRedrawFlags.isEdgesDirty = true;
    },
    zoom_scale() {
      console.log('zoom_scale Changed');
      // This one should set all map flags as dirty to redraw the whole map.
      this.mapDataRedrawFlags.isVehiclesDirty = true;
      this.mapDataRedrawFlags.isNodesDirty = true;
      this.mapDataRedrawFlags.isEdgesDirty = true;
    },
    selectedNode() {
      console.log('selectedNode Changed');
      // This sets nodes as dirty to redraw selected one
      this.mapDataRedrawFlags.isNodesDirty = true;
    },
    selectedEdge() {
      console.log('selectedEdge Changed');
      // This sets edges as dirty to redraw selected one
      // this.mapDataRedrawFlags.isEdgesDirty = true;
    },
    visibleNodes: {
      handler() {
        console.log('Visible Nodes Changed');
        // This sets nodes as dirty to redraw with a new position
        this.mapDataRedrawFlags.isNodesDirty = true;
      },
      deep:true
    },
    visibleEdges: {
      handler() {
        console.log('Visible Edges Changed');
        // This sets edges as dirty to redraw with a new position
        this.mapDataRedrawFlags.isEdgesDirty = true;
      },
      deep:true
    },
    vehicleStates() {
      console.log('vehicleStates Changed');
      // This sets vehicles as dirty to redraw with a new position
      this.mapDataRedrawFlags.isVehiclesDirty = true;
    },
    showVehicleLabels(newValue) {
      useConfig().updateLocalConfig({showVehicleLabels: newValue});
    },
    showNodeLabels(newValue) {
      useConfig().updateLocalConfig({showNodeLabels: newValue});
    }
  }
})
</script>

<style>
#myCanvas {
  border: 1px solid gray;
}
.gray {
  color: #7a7a7a;
}
.input {
  color: #1F2936;
}
</style>
